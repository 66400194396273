<template>
    <v-autocomplete label="태그" prefix="＃" v-model="text" :items="items" v-bind="attrs_input" />
</template>

<script>
import { attrs_input, SERVICES } from "@/assets/variables";
const items = Object.values(SERVICES);

export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        text: null,

        items,
        attrs_input: { ...attrs_input, hideDetails: false },
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.text = this.value;
        },
        text() {
            this.$emit("input", this.text);
        },
    },
    methods: {
        sync() {
            this.text = this.value;
        },
    },
};
</script>

<style></style>
