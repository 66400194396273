<template>
    <v-layout justify-center>
        <v-responsive max-width="1680px" width="100%">
            <list-heading v-model="showsSearch" title="후기 목록" showsFilterButton />
            <review-list-search v-bind="{ showsSearch }" />

            <v-row class="ma-n2">
                <v-spacer />
                <v-col cols="auto" class="pa-2" style="max-width: 180px">
                    <review-list-sort solo dense flat hide-details class="white" />
                </v-col>
            </v-row>
            <v-data-table v-bind="{ items, headers, loading }" hide-default-footer disable-sort dense class="my-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.locationName`]="{ item }">
                    <review-edit-location :value="item" @input="update" />
                </template>
                <template #[`item.meta.likes`]="{ item }">
                    <review-edit-likes :value="item" @input="update" />
                </template>
                <template #[`item.meta.isApproved`]="{ item }">
                    <review-edit-is-approved :value="item" @input="update" />
                </template>
                <template #[`item.meta.isBest`]="{ item }">
                    <review-edit-is-best :value="item" @input="update" />
                </template>
                <template #[`item.receipts`]="{ value }">
                    <template v-if="value?.length">
                        <image-tiles :images="(value || []).map((receipt) => receipt?.img?.url).filter((item) => item)" tilesOnly cols="12" />
                    </template>
                    <template v-else> - </template>
                </template>
                <template #[`item.meta.isRewarded`]="{ item }">
                    <review-edit-reward :value="item" @input="update" />
                </template>
                <template #[`item.tags`]="{ item }">
                    <review-edit-tags :value="item" @input="update" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <review-view :value="item" @input="update">
                        <template #activator="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" small text icon color="primary"> <v-icon small>mdi-file</v-icon> </v-btn>
                        </template>
                    </review-view>
                    <review-form :value="item" @input="update">
                        <template #activator="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" small text icon color="primary"> <v-icon small>mdi-pencil</v-icon> </v-btn>
                        </template>
                    </review-form>
                    <v-btn small text icon color="red" @click="remove(item)"> <v-icon small>mdi-delete</v-icon> </v-btn>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import ReviewView from "@/components/console/eyes/review-view.vue";
import ReviewForm from "@/components/console/eyes/review-form.vue";
import ImageTiles from "@/components/dumb/image-tiles/image-tiles.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ReviewListSort from "@/components/console/eyes/review-list-sort.vue";
import ReviewEditTags from "@/components/console/eyes/review-edit-tags.vue";
import ReviewEditLikes from "@/components/console/eyes/review-edit-likes.vue";
import ReviewListSearch from "@/components/console/eyes/review-list-search.vue";
import ReviewEditReward from "@/components/console/eyes/review-edit-reward.vue";
import ReviewEditIsBest from "@/components/console/eyes/review-edit-is-best.vue";
import ReviewEditLocation from "@/components/console/eyes/review-edit-location.vue";
import ReviewEditIsApproved from "@/components/console/eyes/review-edit-is-approved.vue";

const formatter_dateTime = (value) => value?.toDateTime?.() || "-";
const headers = [
    { width: 110, text: "작성일", value: "createdAt", formatter: formatter_dateTime, divider: true },
    { width: 120, text: "병원명", value: "locationName", divider: true },
    { text: "제목", value: "subject", cellClass: "text-truncate", divider: true },
    { width: 60, text: "별점", value: "stars.total", divider: true },
    { width: 80, text: "작성자", value: "writer.name", formatter: (value) => value || "-" },
    { width: 120, text: "닉네임", value: "writer.nickname" },
    { width: 130, text: "연락처", value: "phone", formatter: (value) => value?.phoneNumberFormat?.() || value || "-" },
    { width: 170, text: "이메일", value: "writer.email" },
    { width: 130, text: "IP", value: "ip", formatter: (value) => value || "-", divider: true },
    { width: 80, text: "추천수", value: "meta.likes", align: "right", divider: true },
    { width: 90, text: "리뷰승인", value: "meta.isApproved", divider: true, align: "center" },
    { width: 100, text: "베스트리뷰", value: "meta.isBest", divider: true, align: "center" },
    { width: 55, text: "영수증", value: "receipts", class: "pr-0", cellClass: "pr-0" },
    { width: 110, text: "보상지급여부", value: "meta.isRewarded", divider: true },
    { width: 110, text: "태그", value: "tags", divider: true },
    { width: 110, text: "", value: "actions", align: "center", cellClass: "px-0" },
];

export default {
    components: {
        ReviewView,
        ReviewForm,
        ImageTiles,
        ListHeading,
        ReviewListSort,
        ReviewEditTags,
        ReviewEditLikes,
        ReviewListSearch,
        ReviewEditReward,
        ReviewEditIsBest,
        ReviewEditLocation,
        ReviewEditIsApproved,
    },
    data: () => ({
        reviews: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return this.reviews;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        sort() {
            return this.$route.query.sort;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.sort;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
        sort() {
            this.search();
        },
    },
    methods: {
        init() {
            this.search();
        },
        manualSearch() {
            this.page = 1;
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let { skip, limit, sort, params } = this;
                var { summary, reviews } = await api.console.eyes.reviews.gets({
                    headers: { skip, limit, sort },
                    params,
                });

                this.reviews = reviews;
                this.summary = summary;

                // reviews.forEach((review) => {
                //     const size = new TextEncoder().encode(JSON.stringify(review)).length;
                //     const kiloBytes = size / 1024;
                //     const megaBytes = kiloBytes / 1024;
                //     console.log({ size, kiloBytes, megaBytes });
                // });
            } finally {
                this.loading = false;
            }
        },
        async remove(item) {
            if (!confirm(`리뷰를 삭제하시겠습니까?\n\n제목:    "${item?.subject || ""}"\n작성일: ${item?.createdAt?.toDateTime()}`)) return;

            await api.console.eyes.reviews.delete(item);
            this.reviews = this.reviews.filter((content) => content._id !== item._id);
            alert("리뷰가 삭제되었습니다.");
        },
        async update(item) {
            const index = this.reviews.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.reviews.splice(index, 1, item);
            else {
                this.reviews = [item, ...this.reviews];
                this.totalCount += 1;
            }
            this.search(false);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td {
    font-size: 0.75em !important;
    &.text-truncate {
        max-width: 1px;
    }
}
</style>
