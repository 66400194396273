<template>
    <v-tab-item value="tags">
        <v-data-table :items="items" :headers="headers" hide-default-footer disable-sort>
            <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
            <template #[`item.isOn`]="{ item }">
                <tag-edit-is-on :value="item" @input="update" />
            </template>
            <template #[`item.actions`]="{ item }">
                <tag-view :value="item" @input="update">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" text icon color="primary"><v-icon>mdi-pencil</v-icon></v-btn>
                    </template>
                </tag-view>
                <v-btn icon text color="red" @click="remove(item)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
            <template #footer>
                <v-divider />
                <v-row justify="end">
                    <v-col cols="auto">
                        <tag-view @input="update">
                            <template #activator="{ attrs, on }">
                                <v-btn tile color="primary" v-bind="attrs" v-on="on"> <v-icon class="mr-2">mdi-plus</v-icon> 태그 추가 </v-btn>
                            </template>
                        </tag-view>
                    </v-col>
                </v-row>
            </template>
        </v-data-table>
        <v-divider />
        <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="my-2" @input="search()" />
        <v-divider />
    </v-tab-item>
</template>

<script>
import ListHeading from "@/components/console/dumb/list-heading.vue";
import TagView from "./tag-view.vue";

import api from "@/api";
import { TAG_TYPES } from "@/assets/variables";
import TagEditIsOn from "./tag-edit-is-on.vue";
const formatter_dateTime = (value) => value?.toDateTime?.() || "-";

const headers = [
    { text: "작성일", value: "createdAt", width: 110, formatter: formatter_dateTime },
    { text: "유형", value: "type", formatter: (value) => TAG_TYPES[value]?.text },
    { text: "태그", value: "tag" },
    { text: "메인", value: "isOn" },
    { text: "", value: "actions", width: 110, align: "right" },
];

export default {
    components: {
        ListHeading,
        TagView,
        TagEditIsOn,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
            },

            page: +this.$route.query.page || 1,
            totalCount: 0,
            limit: 10,

            tags: [],
            headers,
        };
    },
    computed: {
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        items() {
            return this.tags;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        init() {
            this.search(false);
        },
        manualSearch() {
            this.page = 1;
            this.search();
        },
        async search(routable = true) {
            const { page, skip, limit } = this;
            if (routable) this.$router.push({ query: Object.assign({}, this.filter, { page }) });
            var {
                summary: { totalCount },
                tags,
            } = await api.console.eyes.tags.gets({
                headers: { skip, limit },
                params: this.filter,
            });

            this.tags = tags;
            this.totalCount = totalCount;
        },
        async remove(item) {
            if (!confirm(`태그를 삭제하시겠습니까?\n\n제목:    "${item?.text || ""}"\n작성일: ${item?.createdAt?.toDateTime()}`)) return;

            await api.console.eyes.tags.delete(item);
            this.tags = this.tags.filter((tag) => tag._id !== item._id);
            alert("태그가 삭제되었습니다.");
        },
        async update(item) {
            const index = this.tags.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.tags.splice(index, 1, item);
            else {
                this.tags = [item, ...this.tags];
                this.totalCount += 1;
            }
        },
    },
};
</script>
