var render = function render(){
  var _vm$inputs, _vm$inputs$writer, _vm$inputs2, _vm$inputs2$writer, _vm$inputs3, _vm$inputs3$writer, _vm$inputs3$writer$ph, _vm$inputs3$writer$ph2, _vm$inputs4, _vm$inputs4$writer, _vm$inputs5, _vm$inputs5$location, _vm$inputs6, _vm$inputs6$location, _vm$inputs7, _vm$inputs8, _vm$inputs8$charges, _vm$inputs8$charges$t, _vm$inputs8$charges$t2, _vm$inputs9, _vm$inputs10, _vm$inputs11, _vm$inputs12, _vm$inputs12$receipts, _vm$inputs13, _vm$inputs13$receipts, _vm$inputs14;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "1080",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.isLoaded ? _c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card-title', [_vm._v(" 리뷰 상세 "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "height": "calc(90vh - 120px)",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    staticStyle: {
      "min-height": "calc(100% - 3px)"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-card-subtitle', {
    staticClass: "grey lighten-4"
  }, [_vm._v("작성자 정보")]), _c('v-divider'), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("이름")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$inputs = _vm.inputs) === null || _vm$inputs === void 0 ? void 0 : (_vm$inputs$writer = _vm$inputs.writer) === null || _vm$inputs$writer === void 0 ? void 0 : _vm$inputs$writer.name))])], 1), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("닉네임")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$inputs2 = _vm.inputs) === null || _vm$inputs2 === void 0 ? void 0 : (_vm$inputs2$writer = _vm$inputs2.writer) === null || _vm$inputs2$writer === void 0 ? void 0 : _vm$inputs2$writer.nickname))])], 1), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("연락처")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$inputs3 = _vm.inputs) === null || _vm$inputs3 === void 0 ? void 0 : (_vm$inputs3$writer = _vm$inputs3.writer) === null || _vm$inputs3$writer === void 0 ? void 0 : (_vm$inputs3$writer$ph = _vm$inputs3$writer.phone) === null || _vm$inputs3$writer$ph === void 0 ? void 0 : (_vm$inputs3$writer$ph2 = _vm$inputs3$writer$ph.phoneNumberFormat) === null || _vm$inputs3$writer$ph2 === void 0 ? void 0 : _vm$inputs3$writer$ph2.call(_vm$inputs3$writer$ph)))])], 1), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("이메일")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$inputs4 = _vm.inputs) === null || _vm$inputs4 === void 0 ? void 0 : (_vm$inputs4$writer = _vm$inputs4.writer) === null || _vm$inputs4$writer === void 0 ? void 0 : _vm$inputs4$writer.email))])], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "grey lighten-4"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("병원")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$inputs5 = _vm.inputs) === null || _vm$inputs5 === void 0 ? void 0 : (_vm$inputs5$location = _vm$inputs5.location) === null || _vm$inputs5$location === void 0 ? void 0 : _vm$inputs5$location.name) + " / " + _vm._s((_vm$inputs6 = _vm.inputs) === null || _vm$inputs6 === void 0 ? void 0 : (_vm$inputs6$location = _vm$inputs6.location) === null || _vm$inputs6$location === void 0 ? void 0 : _vm$inputs6$location.areaName))])], 1), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("의사")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$inputs7 = _vm.inputs) === null || _vm$inputs7 === void 0 ? void 0 : _vm$inputs7.doctor))])], 1), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("진료")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(_vm.services))])], 1), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("금액")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(((_vm$inputs8 = _vm.inputs) === null || _vm$inputs8 === void 0 ? void 0 : (_vm$inputs8$charges = _vm$inputs8.charges) === null || _vm$inputs8$charges === void 0 ? void 0 : (_vm$inputs8$charges$t = _vm$inputs8$charges.total) === null || _vm$inputs8$charges$t === void 0 ? void 0 : (_vm$inputs8$charges$t2 = _vm$inputs8$charges$t.format) === null || _vm$inputs8$charges$t2 === void 0 ? void 0 : _vm$inputs8$charges$t2.call(_vm$inputs8$charges$t)) || "-") + " 원")])], 1), _c('v-divider'), _c('review-view-stars', {
    attrs: {
      "value": _vm.inputs,
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "grey lighten-4 d-flex align-center justify-space-between"
  }, [_vm._v(" 리뷰 상세정보 ")]), _c('v-divider'), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "ma-3 font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("제목 : ")]), _c('v-col', {
    staticClass: "ma-3"
  }, [_vm._v(_vm._s((_vm$inputs9 = _vm.inputs) === null || _vm$inputs9 === void 0 ? void 0 : _vm$inputs9.subject))])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "review-content"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$inputs10 = _vm.inputs) === null || _vm$inputs10 === void 0 ? void 0 : _vm$inputs10.content)
    }
  }), _vm._l(((_vm$inputs11 = _vm.inputs) === null || _vm$inputs11 === void 0 ? void 0 : _vm$inputs11.photos) || [], function (photo) {
    return [_c('v-img', {
      key: photo._id,
      staticClass: "my-3 rounded-xl",
      attrs: {
        "src": photo.url
      }
    })];
  })], 2), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 pa-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 영수증 - "), !!((_vm$inputs12 = _vm.inputs) !== null && _vm$inputs12 !== void 0 && (_vm$inputs12$receipts = _vm$inputs12.receipts) !== null && _vm$inputs12$receipts !== void 0 && _vm$inputs12$receipts.length) ? [_vm._v(" 인증 ")] : [_vm._v(" 미인증 ")]], 2), (_vm$inputs13 = _vm.inputs) !== null && _vm$inputs13 !== void 0 && (_vm$inputs13$receipts = _vm$inputs13.receipts) !== null && _vm$inputs13$receipts !== void 0 && _vm$inputs13$receipts.length ? [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('image-tiles', {
    attrs: {
      "images": (((_vm$inputs14 = _vm.inputs) === null || _vm$inputs14 === void 0 ? void 0 : _vm$inputs14.receipts) || []).map(function (receipt) {
        var _receipt$img;
        return receipt === null || receipt === void 0 ? void 0 : (_receipt$img = receipt.img) === null || _receipt$img === void 0 ? void 0 : _receipt$img.url;
      }).filter(function (item) {
        return item;
      }),
      "tilesOnly": ""
    }
  })], 1)] : _vm._e()], 2), _c('v-divider')], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "d-flex align-center subtitle-2"
  }, [_vm.inputs.meta.isRewarded ? _c('i', {
    staticClass: "mx-1"
  }, [_vm._v(" * 보상 지급 완료 ")]) : _c('i', {
    staticClass: "mx-1"
  }, [_vm._v(" * 보상 미지급 ")]), _c('v-spacer'), _c('review-edit-reward', {
    attrs: {
      "hasCustomActivator": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.inputs);
      }
    },
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  }, [_vm.inputs.meta.isRewarded ? _c('v-btn', {
    attrs: {
      "color": "secondary",
      "large": ""
    }
  }, [_vm._v("보상 지급 확인 및 수정")]) : _c('v-btn', {
    attrs: {
      "color": "secondary",
      "large": ""
    }
  }, [_vm._v("보상 지급")])], 1)], 1)], 1) : _vm._e(), _c('daum-postcode', {
    ref: "daum-postcode",
    on: {
      "change": _vm.setAddress
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }