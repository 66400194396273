var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-1",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm.user._id ? _c('span', [_vm._v("회원 상세보기")]) : _c('span', [_vm._v("회원 등록")])]), _c('v-card', {
    staticClass: "my-4"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("계정")]), _c('v-divider'), _c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.user.createdAt.toDateTime(),
      "label": "가입일시",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "비밀번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("기본 정보")]), _c('v-divider'), _vm.user.type == _vm.PERSON.value ? [_c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "닉네임",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.user, "nickname", $$v);
      },
      expression: "user.nickname"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  })], 1)], 1)] : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "d-none d-md-block",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "d-block d-md-none"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("이벤트수신")]), _c('v-divider'), _c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "hideDetails": "",
      "inset": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('span', {
          class: {
            'subtitle-2': true,
            'primary--text': _vm.user.allowsAds
          }
        }, [_vm._v(_vm._s(_vm.user.allowsAds ? "동의" : "미동의"))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.user.allowsAds,
      callback: function ($$v) {
        _vm.$set(_vm.user, "allowsAds", $$v);
      },
      expression: "user.allowsAds"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("주의회원")]), _c('v-divider'), _c('v-row', {
    staticClass: "mx-1 my-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "hideDetails": "",
      "inset": "",
      "dense": "",
      "color": "red lighten-1"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('span', {
          class: {
            'subtitle-2': true,
            'red--text text--lighten-1': _vm.user.isBlacklisted
          }
        }, [_vm._v(_vm._s(_vm.user.isBlacklisted ? "주의" : "-"))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.user.isBlacklisted,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isBlacklisted", $$v);
      },
      expression: "user.isBlacklisted"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("관리자메모")]), _c('v-divider'), _c('v-card-text', [_c('v-textarea', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "hide-details": "",
      "rows": 7,
      "auto-grow": ""
    },
    model: {
      value: _vm.user.note,
      callback: function ($$v) {
        _vm.$set(_vm.user, "note", $$v);
      },
      expression: "user.note"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "align-center grey lighten-4"
  }, [_c('v-switch', {
    staticClass: "my-0 mx-3 pa-0",
    attrs: {
      "color": "red lighten-1",
      "dense": "",
      "inset": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.user.isDummy,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isDummy", $$v);
      },
      expression: "user.isDummy"
    }
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" 관리자 생성 아이디 ")])]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1), _c('v-row', {
    staticClass: "mx-1 my-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  })], 1)], 1), _c('daum-postcode', {
    ref: "DaumPostcode",
    on: {
      "input": _vm.setAddress
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }