<template>
    <v-dialog v-model="showsDialog" width="1600" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card height="100%">
            <v-card-title> 대량 업로드 <v-spacer /> <v-icon @click="showsDialog = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-file-input ref="excel_file" v-model="excel_file" label="엑셀파일" v-bind="attrs_input" prepend-icon="mdi-file-excel" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" placeholder="엑셀 다운로드와 동일한 양식으로 파일을 업로드해주세요" />
            <v-divider />
            <v-card-text class="pa-0" style="position:relative;height:60vh;overflow-y:hidden;">
                <v-data-table v-model="selected" :headers="headers" :items="items" v-bind="attrs_table" :item-class="itemClass" item-key="index">
                    <template v-for="header in headers" #[`item.${header.value}`]="{ value }">
                        <v-tooltip top :key="header.value">
                            <template #activator="{ attrs, on }">
                                <span v-bind="attrs" v-on="on">{{ value }}</span>
                            </template>
                            <div>{{ value }}</div>
                        </v-tooltip>
                    </template>
                    <template #[`item._id`]="{ item, value }">
                        <template v-if="!item.isToUpload">
                            <v-tooltip top color="error">
                                <template #activator="{ attrs, on }">
                                    <span v-bind="attrs" v-on="on" class="d-flex align-center red" style="width:100%; height:100%;">{{ value }}</span>
                                </template>
                                <div>"{{ value }}"</div>
                                <div>유효하지 않은 아이디입니다.</div>
                            </v-tooltip>
                        </template>
                        <v-tooltip v-else top>
                            <template #activator="{ attrs, on }">
                                <span v-bind="attrs" v-on="on">{{ value }}</span>
                            </template>
                            <div>{{ value }}</div>
                        </v-tooltip>
                    </template>
                    <template #[`item.name`]="{ item, value }">
                        <template v-if="!item.hasName">
                            <v-tooltip top color="error">
                                <template #activator="{ attrs, on }">
                                    <div v-bind="attrs" v-on="on" class="d-flex align-center red" style="width:100%; height:100%;">{{ value }}</div>
                                </template>
                                <div>name은 필수값입니다</div>
                            </v-tooltip>
                        </template>
                        <v-tooltip v-else top>
                            <template #activator="{ attrs, on }">
                                <span v-bind="attrs" v-on="on">{{ value }}</span>
                            </template>
                            <div>{{ value }}</div>
                        </v-tooltip>
                    </template>
                    <template #[`item.homepage`]="{ value }">
                        <v-tooltip top>
                            <template #activator="{ attrs, on }">
                                <span v-bind="attrs" v-on="on">
                                    <v-btn v-if="value && /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(value)" icon text :href="value" target="_blank"> <v-icon>mdi-link</v-icon> </v-btn>
                                    <template v-else>{{ value }}</template>
                                </span>
                            </template>
                            <div>{{ value }}</div>
                        </v-tooltip>
                    </template>
                    <template #[`item.address1`]="{ item, value }">
                        <template v-if="!item.hasAddress1">
                            <v-tooltip top color="error">
                                <template #activator="{ attrs, on }">
                                    <div v-bind="attrs" v-on="on" class="d-flex align-center red" style="width:100%; height:100%;">{{ value }}</div>
                                </template>
                                <div>address1은 필수값입니다</div>
                            </v-tooltip>
                        </template>
                        <v-tooltip v-else top>
                            <template #activator="{ attrs, on }">
                                <span v-bind="attrs" v-on="on">{{ value }}</span>
                            </template>
                            <div>{{ value }}</div>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-center align-center no-gutters">
                <v-col cols="12" lg="auto" class="subtitle-2 font-italic">
                    ※ 총 <b class="text--warning">{{ items.length }}</b> 건 중 <b>{{ locationsUploadable.length }}</b> 건의 데이터를 업로드 할 수 있습니다.
                </v-col>
                <v-spacer />
                <v-col cols="auto" v-show="showsResult"> 총 {{ locationsUploaded.length }} 건 업로드 완료 ({{ locationsCreated.length }} 건 생성 / {{ locationsUpdated.length }} 건 수정 ) </v-col>
                <v-col cols="auto" class="ml-2">
                    <v-btn :disabled="disabledBtn" color="primary" @click="posts">업로드</v-btn>
                </v-col>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay v-show="loading" absolute color="white">
                    <v-progress-circular size="200" width="10" color="primary" indeterminate />
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import XLSX from "xlsx";
import api from "@/api";
import { getGeolocationAndMore } from "@/plugins/getGeolocationAndMore";

const attrs_input = {
    dense: true,
    outlined: true,
    hideDetails: true,
    persistentPlaceholder: true,
    class: "mx-6 my-3",
};
const attrs_table = {
    dense: true,
    height: "60vh",
    itemsPerPage: 100,
    showSelect: true,
    fixedHeader: true,
    disableSort: true,
    disablePagination: true,
    hideDefaultFooter: true,
    selectableKey: "isUploadable",
};
const headers = [
    { cellClass: "text-truncate", text: "_id", value: "_id", width: 230 },
    { cellClass: "text-truncate", text: "name", value: "name", width: 150 },
    { cellClass: "text-truncate", text: "phone", value: "phone", width: 140 },
    { cellClass: "text-truncate", text: "email", value: "email", width: 130 },
    { cellClass: "text-truncate", text: "homepage", value: "homepage", width: 90 },
    { cellClass: "text-truncate", text: "postcode", value: "postcode", width: 90 },
    { cellClass: "text-truncate", text: "address1", value: "address1", width: 250 },
    { cellClass: "text-truncate", text: "address2", value: "address2", width: 250 },
    { cellClass: "text-truncate", text: "비고", value: "memo", width: 180 },
];
const getItemMemo = (item = {}) => {
    if (item.isFailed) return "address1의 값이 잘못되었습니다";
    if (item.isCreated) return "데이터 생성이 완료되었습니다";
    if (item.isUpdated) return "데이터 수정이 완료되었습니다";
    if (item.hasSameData) return "서버 데이터와 동일합니다";
    if (!item.hasName) return "병원명은 필수값입니다";
    if (!item.hasAddress1) return "기본 주소는 필수값입니다";
    return null;
};

export default {
    data: () => ({
        headers,
        attrs_table,
        attrs_input,

        loading: false,
        showsDialog: false,
        showsResult: false,
        disabledBtn: false,

        selected: [],
        excel_file: null,
        locationsOnCloud: [],
        locationsOnExcel: [],

        locationsFailed: [],
        locationsCreated: [],
        locationsUpdated: [],
    }),
    computed: {
        items() {
            return this.locationsOnExcel.map(({ _id, name, phone, email, homepage, postcode, address1, address2 } = {}, index) => {
                let item = {
                    _id: _id?.trim?.() || _id,
                    name: name?.trim?.() || name,
                    phone: phone?.trim?.() || phone,
                    email: email?.trim?.() || email,
                    homepage: homepage?.trim?.() || homepage,
                    postcode: postcode?.trim?.() || postcode,
                    address1: address1?.trim?.() || address1,
                    address2: address2?.trim?.() || address2,
                };
                const onCloudItem = this.locationsOnCloud.find((item) => item?._id == _id) || {};

                const isToCreate = (_id?.length || 0) == 0;
                const isToUpdate = /[a-z0-9]{24}/.test(_id || "");
                const isToUpload = isToCreate || isToUpdate;

                const hasName = (name?.length || 0) > 0;
                const hasAddress1 = (address1?.length || 0) > 0;
                const hasSameData = isToUpdate && !Object.entries(item).some(([key, value] = []) => onCloudItem[key] != value);

                const isFailed = this.locationsFailed.some((item) => item.index == index);
                const isCreated = this.locationsCreated.some((item) => item.name == name && item.phone == phone);
                const isUpdated = this.locationsUpdated.some((item) => item._id == _id);
                const isUploadable = isToUpload && hasName && hasAddress1 && !hasSameData && !isCreated && !isUpdated;

                item = {
                    index,
                    ...item,
                    onCloudItem, //     서버데이터

                    isToCreate, //      생성대상
                    isToUpdate, //      수정대상
                    isToUpload, //      업로드대상

                    hasName, //         데이터 없음(병원명)
                    hasAddress1, //     데이터 없음(기본주소)
                    hasSameData, //     동일 데이터(클라우드 서버와 파일의 데이터가 동일한 경우)

                    isFailed, //        업로드 실패
                    isCreated, //       업로드 생성
                    isUpdated, //       업로드 수정
                    isUploadable, //    업로드 가능
                };

                item = {
                    ...item,
                    memo: getItemMemo(item),
                };

                return item;
            });
        },
        selectedToCreate() {
            return this.selected.filter(({ isToCreate }) => isToCreate);
        },
        selectedToUpdate() {
            return this.selected.filter(({ isToUpdate }) => isToUpdate);
        },
        locationsUploaded() {
            return [...this.locationsCreated, ...this.locationsUpdated];
        },
        locationsUploadable() {
            return this.items.filter(({ isUploadable }) => isUploadable);
        },
    },
    watch: {
        excel_file() {
            if (this.excel_file) {
                this.init({ withoutExcel: true });
                this.loading = true;
                const reader = new FileReader();
                reader.onload = async () => {
                    const workBook = XLSX.read(reader.result, { type: "binary" });
                    const sheets = workBook.SheetNames.map((key) => XLSX.utils.sheet_to_json(workBook.Sheets[key]));
                    if (sheets[0].length > 100) {
                        alert("1회에 100건 이상의 데이터를 요청하실 수 없습니다.");
                        this.loading = false;
                        this.excel_file = null;
                        return;
                    }
                    this.locationsOnExcel = sheets[0]; // 첫번째 시트

                    const params = {
                        searchKey: "_id",
                        searchValue: this.locationsOnExcel.map(({ _id }) => _id).filter((_id) => !!_id && /[a-z0-9]{24}/.test(_id)),
                    };
                    this.locationsOnCloud = (await api.console.eyes.locations.gets({ params }))?.locations;
                    this.loading = false;
                };
                reader.readAsBinaryString(this.excel_file);
            } else {
                this.init();
            }
        },
        showsDialog() {
            if (this.showsDialog) this.init();
            else {
                if (this.locationsUpdated.length > 0) this.$nextTick(() => this.$router.go());
            }
        },
    },
    methods: {
        init({ withoutExcel = false } = {}) {
            this.loading = false;
            this.showsResult = false;
            this.disabledBtn = false;
            this.selected = [];
            if (!withoutExcel) this.excel_file = null;
            this.locationsOnCloud = [];
            this.locationsOnExcel = [];
            this.locationsFailed = [];
            this.locationsCreated = [];
            this.locationsUpdated = [];
        },
        itemClass({ isFailed, isCreated, isUpdated, isToUpload, hasSameData, hasName, hasAddress1 } = {}) {
            return {
                warning: isFailed,
                success: isCreated || isUpdated,
                "grey--text": hasSameData,
                "font-weight-bold": isToUpload && !hasSameData,
                "text-decoration-line-through": !isToUpload || !hasName || !hasAddress1,
            };
        },
        async posts() {
            this.disabledBtn = true;
            this.loading = true;
            try {
                // [postcode, geolocation] binding
                const toCreate = [];
                for (const { ...item } of this.selectedToCreate) {
                    const { geolocation, postcode, address1 } = (await getGeolocationAndMore(item.address1)) || {};
                    item.geolocation = geolocation;
                    item.postcode = postcode;
                    item.address1 = address1;
                    if (item.geolocation) toCreate.push(item);
                    else this.locationsFailed.push(item);
                }
                const toUpdate = [];
                for (const { ...item } of this.selectedToUpdate) {
                    const { geolocation, postcode, address1 } = (await getGeolocationAndMore(item.address1)) || {};
                    item.geolocation = geolocation;
                    item.postcode = postcode;
                    item.address1 = address1;
                    if (item.geolocation) toUpdate.push(item);
                    else this.locationsFailed.push(item);
                }

                // [toCreate, toUpdate] uploading
                this.locationsCreated = await Promise.all(toCreate.map(async ({ name, phone, email, postcode, address1, address2 = "", geolocation }) => (await api.console.eyes.locations.post({ name, phone, email, postcode, address1, address2, geolocation }))?.location));
                this.locationsUpdated = await Promise.all(toUpdate.map(async ({ _id, name, phone, email, postcode, address1, address2 = "", geolocation }) => (await api.console.eyes.locations.put({ _id, name, phone, email, postcode, address1, address2, geolocation }))?.location));

                // [locationsOnCloud] binding
                this.locationsOnCloud = [...this.locationsOnCloud.map((item) => this.locationsUpdated.find(({ _id }) => _id == item._id) || item), ...this.locationsCreated];

                // inform
                this.showsResult = true;
                this.loading = false;

                alert(`총 ${this.locationsUploaded.length} 건의 데이터를 업로드하였습니다.\n\n생성: ${this.locationsCreated.length}건\n수정: ${this.locationsUpdated.length}건`);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .text-truncate {
    max-width: 1px;
}
</style>
