var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-card-subtitle', {
    staticClass: "grey lighten-4"
  }, [_vm._v("평가정보")]), _c('v-divider'), _vm._l(_vm.items, function (item, index) {
    return _c('v-row', {
      key: item.value,
      staticClass: "ma-3",
      attrs: {
        "no-gutters": "",
        "align": "center",
        "justify": "space-between"
      }
    }, [_c('v-col', {
      staticClass: "font-size-14",
      attrs: {
        "cols": "auto"
      }
    }, [item.bold ? _c('b', [_vm._v(_vm._s(item.text))]) : _c('span', [_vm._v(_vm._s(item.text))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-rating', _vm._b({
      attrs: {
        "half-increments": "",
        "half-icon": "mdi-star-half",
        "color": "warning",
        "background-color": "warning",
        "dense": "",
        "size": "16"
      },
      on: {
        "input": _vm.emit
      },
      model: {
        value: _vm.stars[item.value],
        callback: function ($$v) {
          _vm.$set(_vm.stars, item.value, $$v);
        },
        expression: "stars[item.value]"
      }
    }, 'v-rating', {
      readonly: index == 0 || _vm.readonly
    }, false))], 1)], 1);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }