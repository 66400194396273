var render = function render(){
  var _vm$inputs, _vm$inputs$writer, _vm$inputs2, _vm$inputs2$writer, _vm$inputs3, _vm$inputs3$writer, _vm$inputs3$writer$ph, _vm$inputs3$writer$ph2, _vm$inputs4, _vm$inputs4$writer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "1080",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.isLoaded ? _c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card-title', [_vm._v(" 리뷰 상세 "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "height": "calc(90vh - 120px)",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    staticStyle: {
      "min-height": "calc(100% - 3px)"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-card-subtitle', {
    staticClass: "grey lighten-4"
  }, [_vm._v("작성자 정보")]), _c('v-divider'), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("이름")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$inputs = _vm.inputs) === null || _vm$inputs === void 0 ? void 0 : (_vm$inputs$writer = _vm$inputs.writer) === null || _vm$inputs$writer === void 0 ? void 0 : _vm$inputs$writer.name))])], 1), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("닉네임")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$inputs2 = _vm.inputs) === null || _vm$inputs2 === void 0 ? void 0 : (_vm$inputs2$writer = _vm$inputs2.writer) === null || _vm$inputs2$writer === void 0 ? void 0 : _vm$inputs2$writer.nickname))])], 1), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("연락처")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$inputs3 = _vm.inputs) === null || _vm$inputs3 === void 0 ? void 0 : (_vm$inputs3$writer = _vm$inputs3.writer) === null || _vm$inputs3$writer === void 0 ? void 0 : (_vm$inputs3$writer$ph = _vm$inputs3$writer.phone) === null || _vm$inputs3$writer$ph === void 0 ? void 0 : (_vm$inputs3$writer$ph2 = _vm$inputs3$writer$ph.phoneNumberFormat) === null || _vm$inputs3$writer$ph2 === void 0 ? void 0 : _vm$inputs3$writer$ph2.call(_vm$inputs3$writer$ph)))])], 1), _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("이메일")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s((_vm$inputs4 = _vm.inputs) === null || _vm$inputs4 === void 0 ? void 0 : (_vm$inputs4$writer = _vm$inputs4.writer) === null || _vm$inputs4$writer === void 0 ? void 0 : _vm$inputs4$writer.email))])], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "grey lighten-4"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 pa-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('review-write-clinic', _vm._b({
    attrs: {
      "label": "병원"
    },
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  }, 'review-write-clinic', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "의사"
    },
    model: {
      value: _vm.inputs.doctor,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "doctor", $$v);
      },
      expression: "inputs.doctor"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "진료",
      "items": _vm.services,
      "multiple": ""
    },
    model: {
      value: _vm.inputs.services,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "services", $$v);
      },
      expression: "inputs.services"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "금액",
      "suffix": "원"
    },
    model: {
      value: _vm.inputs.charges.total,
      callback: function ($$v) {
        _vm.$set(_vm.inputs.charges, "total", $$v);
      },
      expression: "inputs.charges.total"
    }
  }, 'v-currency-field', _vm.attrs_input, false))], 1)], 1), _c('v-divider'), _c('review-view-stars', {
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "",
      "md": "8"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "grey lighten-4 d-flex align-center justify-space-between"
  }, [_vm._v(" 리뷰 상세정보 ")]), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 pa-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.inputs.subject,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "subject", $$v);
      },
      expression: "inputs.subject"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "내용"
    },
    model: {
      value: _vm.inputs.content,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "content", $$v);
      },
      expression: "inputs.content"
    }
  }, 'v-textarea', _vm.attrs_input, false))], 1)], 1), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 pa-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("이미지")]), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('images-input', {
    model: {
      value: _vm.inputs.photos,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "photos", $$v);
      },
      expression: "inputs.photos"
    }
  })], 1)], 1), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 pa-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("영수증")]), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('review-form-receipts', {
    model: {
      value: _vm.inputs.receipts,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "receipts", $$v);
      },
      expression: "inputs.receipts"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "d-flex align-center subtitle-2"
  }, [_vm.inputs.meta.isRewarded ? _c('i', {
    staticClass: "mx-1"
  }, [_vm._v(" * 보상 지급 완료 ")]) : _c('i', {
    staticClass: "mx-1"
  }, [_vm._v(" * 보상 미지급 ")]), _c('v-spacer'), _c('review-edit-reward', {
    attrs: {
      "hasCustomActivator": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.inputs);
      }
    },
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  }, [_vm.inputs.meta.isRewarded ? _c('v-btn', {
    staticClass: "px-5",
    attrs: {
      "large": "",
      "color": "secondary"
    }
  }, [_vm._v("보상 지급 확인 및 수정")]) : _c('v-btn', {
    staticClass: "px-5",
    attrs: {
      "large": "",
      "color": "secondary"
    }
  }, [_vm._v("보상 지급")])], 1), _c('v-btn', {
    staticClass: "ml-2 px-5",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("수정하기")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }