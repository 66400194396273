var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-combobox', _vm._b({
    attrs: {
      "search-input": _vm.clinic,
      "items": _vm.items,
      "loading": _vm.loading,
      "item-text": _vm.itemText,
      "no-filter": "",
      "return-object": "",
      "hide-details": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.clinic = $event;
      },
      "update:search-input": function ($event) {
        _vm.clinic = $event;
      }
    },
    model: {
      value: _vm.location,
      callback: function ($$v) {
        _vm.location = $$v;
      },
      expression: "location"
    }
  }, 'v-combobox', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }