<template>
    <v-layout justify-center>
        <v-responsive max-width="720" width="100%">
            <list-heading title="메인 관리" />

            <v-row no-gutters>
                <v-col cols="auto">
                    <v-tabs :value="tab" hide-slider active-class="secondary white--text" style="overflow:hidden;">
                        <v-tab to="/console/eyes/main-setting/tags">태그</v-tab>
                        <v-tab to="/console/eyes/main-setting/counts">데이터 카운트</v-tab>
                    </v-tabs>
                </v-col>
            </v-row>
            <v-divider />
            <v-tabs-items :value="tab">
                <main-tags />
                <main-counts />
            </v-tabs-items>
        </v-responsive>
    </v-layout>
</template>

<script>
import MainTags from "@/components/console/eyes/main-tags.vue";
import MainCounts from "@/components/console/eyes/main-counts.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";

export default {
    components: {
        MainTags,
        MainCounts,
        ListHeading,
    },
    props: {
        tab: { type: String, default: "tags" },
    },
};
</script>
