<template>
    <v-select v-model="query.sort" v-bind="{ ...$attrs, items }" @change="emit" />
</template>

<script>
const items = [
    { text: "작성일 ↓", value: JSON.stringify({ createdAt: -1 }) },
    { text: "추천수 ↓", value: JSON.stringify({ ["meta.likes"]: -1 }) },
];

const initQuery = (query = {}) => ({
    ...query,
    sort: query.sort ?? items[0].value,
});

export default {
    data: () => ({
        query: initQuery(),
        items,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$router.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query;

            delete query.page;

            if (!query.sort) delete query.sort;
            if (query.sort == items[0].value) delete query.sort;

            this.$router.push({ query });
        },
    },
};
</script>

<style>
</style>