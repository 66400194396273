<template>
    <v-sheet>
        <images-input :value="receipts.map(({ img }) => img)" mode="hover" @input="setImages">
            <template #hover-icons="{ index, hover }">
                <v-card v-show="hover" class="pa-2" rounded="xl">
                    <v-switch v-model="receipts[index].shows" v-bind="attrs_switch" class="mr-n4 my-n1" :dense="false" @input="emit" />
                </v-card>
            </template>
        </images-input>
    </v-sheet>
</template>

<script>
import { attrs_switch } from "@/assets/variables";

import ImagesInput from "@/components/console/dumb/images-input.vue";

export default {
    components: {
        ImagesInput,
    },
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        receipts: [],
        attrs_switch,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.receipts = [...this.value];
        },
        emit() {
            this.$emit("input", this.receipts);
        },
        setImages(images) {
            this.receipts = images.map((img) => ({
                ...(this.receipts.find((receipt) => receipt.img == img) || {
                    _img: null,
                    shows: false,
                }),
                img,
            }));
            this.emit();
        },
    },
};
</script>

<style>
</style>