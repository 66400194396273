<template>
    <v-dialog v-model="dialog" :disabled="!src" fullscreen transition="fade-transition">
        <template v-slot:activator="{ attrs, on }">
            <v-avatar v-bind="{ ...attrs, ...avatarAttrs }" v-on="on">
                <v-img :src="src" alt="">
                    <template v-slot:placeholder>
                        <v-icon dark>mdi-image</v-icon>
                    </template>
                </v-img>
            </v-avatar>
        </template>

        <v-card dark class="rounded-0 d-flex justify-center align-center" @click="dialog = false" :ripple="false">
            <v-img :src="src" />
            <v-btn absolute right top icon tile dark x-large @click="dialog = false" style="background-color:rgba(0,0,0,0.3)">
                <v-icon size="4rem">mdi-close</v-icon>
            </v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        src: { type: String, default: null },
        size: { type: String, default: null },
        width: { type: String, default: null },
        height: { type: String, default: null },
    },
    computed: {
        avatarAttrs() {
            const { size, width, height } = this;
            return {
                size,
                width,
                height,
                tile: true,
                color: "grey lighten-1",
            };
        },
    },
    data() {
        return { dialog: false };
    },
};
</script>

<style></style>
