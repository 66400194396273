<template>
    <v-switch v-model="isApproved" readonly dense inset hide-details class="ma-0 ml-2 mr-n4 pa-0" @click="save" />
</template>

<script>
import api from "@/api";
export default {
    props: { value: { type: Object, default: () => ({}) } },
    computed: {
        isApproved() {
            return this.value?.meta?.isApproved;
        },
    },
    methods: {
        validates() {
            try {
                if (!this.isApproved && !this.value?._location) throw new Error("병원이 지정되어 있지 않습니다.\n병원을 먼저 지정해주세요.");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;
            const { value: { _id, meta } = {} } = this;
            const { review } = await api.console.eyes.reviews.put({ _id, meta: { ...(meta || {}), isApproved: !this.isApproved } });
            this.$emit("input", { ...this.value, meta: review.meta });
            alert("저장되었습니다.");
        },
    },
};
</script>

<style></style>
