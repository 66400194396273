<template>
    <v-sheet>
        <v-card-subtitle class="grey lighten-4">평가정보</v-card-subtitle>
        <v-divider />
        <v-row no-gutters align="center" justify="space-between" class="ma-3" v-for="(item, index) in items" :key="item.value">
            <v-col cols="auto" class="font-size-14">
                <b v-if="item.bold">{{ item.text }}</b>
                <span v-else>{{ item.text }}</span>
            </v-col>
            <v-col cols="auto">
                <v-rating v-model="stars[item.value]" v-bind="{ readonly: index == 0 || readonly }" half-increments half-icon="mdi-star-half" color="warning" background-color="warning" dense size="16" @input="emit" />
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { CRITERIA } from "@/assets/variables";

let items = [{ text: "총점", value: "total", bold: true }, ...Object.values(CRITERIA)];
items = [items[0], items[3], items[1], items[4], items[2], items[5]];

export default {
    props: {
        value: { type: Object, default: () => ({}) },
        readonly: { type: Boolean, default: false },
    },
    data: () => ({
        items,
        stars: {},
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.stars = { ...(this.value?.stars || {}) };
        },
        emit() {
            const criteria = Object.values(CRITERIA).map(({ value }) => value);
            this.stars.total = criteria.reduce((sum, key) => sum + (this.stars?.[key] || 0), 0) / criteria.length;

            const { stars } = this;
            this.$emit("input", { ...this.value, stars });
        },
    },
};
</script>

<style></style>
