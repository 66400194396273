var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1680px",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "후기 목록",
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('review-list-search', _vm._b({}, 'review-list-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "pa-2",
    staticStyle: {
      "max-width": "180px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('review-list-sort', {
    staticClass: "white",
    attrs: {
      "solo": "",
      "dense": "",
      "flat": "",
      "hide-details": ""
    }
  })], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "my-4",
    attrs: {
      "hide-default-footer": "",
      "disable-sort": "",
      "dense": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.locationName`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('review-edit-location', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          }
        })];
      }
    }, {
      key: `item.meta.likes`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('review-edit-likes', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          }
        })];
      }
    }, {
      key: `item.meta.isApproved`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('review-edit-is-approved', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          }
        })];
      }
    }, {
      key: `item.meta.isBest`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('review-edit-is-best', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          }
        })];
      }
    }, {
      key: `item.receipts`,
      fn: function (_ref6) {
        var value = _ref6.value;
        return [value !== null && value !== void 0 && value.length ? [_c('image-tiles', {
          attrs: {
            "images": (value || []).map(function (receipt) {
              var _receipt$img;
              return receipt === null || receipt === void 0 ? void 0 : (_receipt$img = receipt.img) === null || _receipt$img === void 0 ? void 0 : _receipt$img.url;
            }).filter(function (item) {
              return item;
            }),
            "tilesOnly": "",
            "cols": "12"
          }
        })] : [_vm._v(" - ")]];
      }
    }, {
      key: `item.meta.isRewarded`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('review-edit-reward', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          }
        })];
      }
    }, {
      key: `item.tags`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('review-edit-tags', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('review-view', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref10) {
              var attrs = _ref10.attrs,
                on = _ref10.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "icon": "",
                  "color": "primary"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-file")])], 1)];
            }
          }], null, true)
        }), _c('review-form', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref11) {
              var attrs = _ref11.attrs,
                on = _ref11.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "icon": "",
                  "color": "primary"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        }), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-delete")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false)), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }