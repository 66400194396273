<template>
    <list-search :showsSearch="showsSearch" @search="search">
        <v-row>
            <v-col lg="3" sm="6">
                <v-select label="회원등급" v-model="filter['_level']" :items="levelItems" item-text="name" item-value="_id" v-bind="attrs_input" />
            </v-col>
            <v-col lg="3" sm="6">
                <v-select label="주의회원" v-model="filter['isBlacklisted']" :items="isBlacklistedItems" item-text="text" item-value="value" v-bind="attrs_input" />
            </v-col>
            <v-col lg="3" sm="6">
                <v-text-field label="닉네임" v-model="filter['nickname']" clearable v-bind="attrs_input" @keydown.enter="search" />
            </v-col>
            <v-col lg="3" sm="6">
                <v-text-field label="연락처" v-model="filter['phone']" clearable v-bind="attrs_input" @keydown.enter="search" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import api from "@/api";
import ListSearch from "../dumb/list-search.vue";

const initFilter = () => ({
    phone: null,
    _level: null,
    nickname: null,
    isApproved: null,
    isBlacklisted: null,
});

const attrs_input = {
    dense: true,
    outlined: true,
    "hide-details": true,
    "persistent-placeholder": true,
};

const isBlacklistedItems = [
    { text: "전체", value: null },
    { text: "주의회원", value: true },
    { text: "미주의회원", value: false },
];

export default {
    components: { ListSearch },
    props: {
        value: { type: Object, default: () => ({}) },
        showsSearch: { type: Boolean, default: false },
    },
    data() {
        return {
            filter: { ...initFilter(), ...(this.value || {}) },
            levels: [],
            isBlacklistedItems,

            attrs_input,
        };
    },
    computed: {
        levelItems() {
            return [{ name: "전체", _id: null }].concat(this.levels);
        },
        parameters() {
            const parameters = JSON.parse(JSON.stringify(this.filter));
            const keys = Object.keys(parameters);
            for (const key of keys) {
                if (typeof parameters[key] == "string" && parameters[key].length < 1) parameters[key] = null;
            }
            return parameters;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.sync();

            try {
                var { levels } = await api.console.levels.gets();
                this.levels = levels;
            } catch (error) {
                console.error(error);
            }
        },
        sync() {
            this.filter = { ...initFilter(), ...(this.value || {}) };
        },
        search() {
            this.$emit("input", this.parameters);
            this.$emit("search");
        },
    },
};
</script>

<style></style>
