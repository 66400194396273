<template>
    <v-edit-dialog large @open="$nextTick(sync)" @save="save" cancel-text="취소" save-text="저장">
        <slot v-if="hasCustomActivator" />
        {{ value?.locationName }}
        <template #input>
            <v-row>
                <v-col>
                    <review-write-clinic v-model="inputs" label="병원명" outlined dense persistent-placeholder />
                </v-col>
            </v-row>
            <v-divider class="mx-n4" style="position:absolute; width: 100%;" />
        </template>
    </v-edit-dialog>
</template>

<script>
import api from "@/api";
import reviewWriteClinic from "@/components/client/reviews/review-write-clinic.vue";
export default {
    components: { reviewWriteClinic },
    props: {
        value: { type: Object, default: () => ({}) }, // review
        hasCustomActivator: { type: Boolean, default: false },
    },
    data: () => ({
        shows: false,
        inputs: {
            _id: null,
            _location: null,
            location: null,
            clinic: null,
        },
    }),
    watch: {
        "value._location"() {
            this.sync();
        },
        "value.clinic"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            const { _id, _location, location, clinic } = this.value;
            this.inputs = { _id, _location, location, clinic };
        },
        async save() {
            let { _id, _location = null, location, clinic } = this.inputs;
            const { review } = await api.console.eyes.reviews.put({ _id, _location, clinic });
            [{ _location, location, clinic }] = [review];
            this.$emit("input", { ...this.value, _location, location, clinic });
        },
    },
};
</script>

<style></style>
