<template>
    <v-row>
        <v-col cols="auto" class="pr-0">
            <image-popup :src="src" :size="size" />
        </v-col>
        <v-col><v-file-input v-model="file" :label="label" accept="image/*" v-bind="inputAttrs"/></v-col>
    </v-row>
</template>

<script>
import ImagePopup from "./image-popup.vue";
const inputAttrs = {
    dense: true,
    outlined: true,
    placeholder: " ",
    "hide-details": true,
};
export default {
    components: { ImagePopup },
    props: {
        value: { type: File, default: null },
        size: { type: String, default: "40" },
        label: { type: String, default: "이미지" },
        imageSrc: { type: String, default: null },
    },
    computed: {
        isOutSync() {
            if (this.value || this.file) return this.value != this.file;
            else return false;
        },
        src() {
            if (this.file) return URL.createObjectURL(this.file);
            else return this.imageSrc;
        },
    },
    data() {
        return {
            file: null,
            inputAttrs,
        };
    },
    methods: {
        init() {
            this.file = this.value;
        },
        emit() {
            this.$emit("input", this.file);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            if (this.isOutSync) this.init();
        },
        file() {
            if (this.isOutSync) this.emit();
        },
    },
};
</script>

<style></style>
