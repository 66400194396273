var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', {
    attrs: {
      "large": "",
      "cancel-text": "취소",
      "save-text": "저장"
    },
    on: {
      "close": _vm.sync,
      "save": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-row', {
          attrs: {
            "align": "baseline"
          }
        }, [_c('v-col', {
          staticClass: "subtitle-2 font-weight-bold",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(_vm._s(_vm.inputs.meta.isRewarded ? "보상지급" : "보상미지급"))]), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "my-0 pa-0 mr-n4",
          attrs: {
            "dense": "",
            "inset": "",
            "hide-details": ""
          },
          model: {
            value: _vm.inputs.meta.isRewarded,
            callback: function ($$v) {
              _vm.$set(_vm.inputs.meta, "isRewarded", $$v);
            },
            expression: "inputs.meta.isRewarded"
          }
        })], 1)], 1), _c('v-divider', {
          staticClass: "mx-n4",
          staticStyle: {
            "position": "absolute",
            "width": "100%"
          }
        }), _c('v-row', [_c('v-col', [_c('v-textarea', {
          attrs: {
            "label": "비고",
            "persistent-placeholder": "",
            "hide-details": "",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.inputs.reward,
            callback: function ($$v) {
              _vm.$set(_vm.inputs, "reward", $$v);
            },
            expression: "inputs.reward"
          }
        })], 1)], 1), _c('v-divider', {
          staticClass: "mx-n4",
          staticStyle: {
            "position": "absolute",
            "width": "100%"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.hasCustomActivator ? _vm._t("default") : _c('v-switch', {
    staticClass: "mr-n8 ml-4 my-0 pa-0",
    staticStyle: {
      "pointer-events": "none"
    },
    attrs: {
      "dense": "",
      "inset": "",
      "readonly": "",
      "hide-details": ""
    },
    model: {
      value: _vm.value.meta.isRewarded,
      callback: function ($$v) {
        _vm.$set(_vm.value.meta, "isRewarded", $$v);
      },
      expression: "value.meta.isRewarded"
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }