<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="880" width="100%" height="100%" class="px-1">
            <v-row no-gutters class="mt-8" align="baseline">
                <v-col cols="auto" class="headline"> {{ headline }}</v-col>
                <v-spacer />
                <v-col cols="auto" class="caption" v-if="isCode(['location-modify-request'])">요청일: {{ form?.createdAt?.toDateTime?.() || form?.createdAt || "" }}</v-col>
            </v-row>
            <v-card class="mt-6" v-if="!isCode(['consignment-sale', 'location-modify-request', 'review-report'])">
                <v-card-title class="subtitle-1">기본정보</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row v-if="$route.params._form">
                        <v-col cols="6" lg="3">
                            <v-text-field :value="(form.writer || {}).name" label="작성자" persistent-placeholder dense hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.createdAt ? form.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder dense hide-details readonly disabled></v-text-field>
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="(form.writer || {}).phone" label="연락처" persistent-placeholder dense hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="(form.writer || {}).email" label="이메일" persistent-placeholder dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6" v-if="!isCode(['consignment-sale', 'location-modify-request', 'review-report'])">
                <v-card-text>
                    <v-text-field v-model="form.meta.type" label="유형" persistent-placeholder hide-details></v-text-field>
                </v-card-text>
            </v-card>

            <v-card class="mt-6" v-if="!isCode(['consignment-sale', 'location-modify-request', 'review-report'])">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-textarea v-model="form.content" outlined></v-textarea>
                </v-card-text>
            </v-card>

            <template v-if="isCode(['consignment-sale'])">
                <v-card class="mt-6">
                    <v-card-title class="subtitle-1">위탁 신청인 정보</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row v-if="$route.params._form">
                            <v-col cols="6" lg="3">
                                <v-text-field :value="form?.meta?.name" label="작성자" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6" lg="3">
                                <v-text-field :value="form.createdAt ? form.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="form?.meta?.phone" label="연락처" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="form?.meta?.email" label="이메일" persistent-placeholder dense hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card class="mt-6">
                    <v-card-title class="subtitle-1">위탁 작품 정보</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row v-if="$route.params._form">
                            <v-col cols="12">
                                <span class="subtitle-2">이미지</span>
                                <v-row>
                                    <v-col v-if="(form?.files || [])?.length < 1">
                                        이미지가 없습니다.
                                    </v-col>
                                    <v-col cols="2" v-for="file in form?.files || []" :key="file._id">
                                        <image-popup :src="file.url" :aspect-ratio="1 / 1" width="100%" />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.author" label="작가명" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.subject" label="작품명" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.material" label="작품의 재료" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.dimension" label="작품의 크기" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.edition" label="Edition" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="form?.meta?.year" label="제작년도(추정)" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-textarea :value="form?.meta?.content" label="작품설명" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-textarea :value="form?.meta?.etc" label="기타" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="(+form?.meta?.price_bought)?.format?.() || form?.meta?.price_bought" label="구입가(KRW)" persistent-placeholder dense hide-details readonly />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :value="(+form?.meta?.price_asking)?.format?.() || form?.meta?.price_asking" label="희망가(KRW)" persistent-placeholder dense hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>

            <template v-if="isCode(['location-modify-request'])">
                <v-card class="my-4" tile>
                    <v-card-subtitle class="d-flex align-center font-weight-bold grey lighten-4">
                        요청인 <v-spacer />
                        <v-btn class="my-n4" text color="blue darken-1" :to="`/console/users/${form?.writer?._id}`">
                            사용자 상세 >
                        </v-btn>
                    </v-card-subtitle>
                    <v-divider />
                    <v-row v-if="$route.params._form" no-gutters>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.writer?.username" label="아이디" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.writer?.name" label="이름" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.writer?.nickname" label="닉네임" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.meta?.phone" label="연락처" persistent-placeholder hide-details readonly />
                        </v-col>
                    </v-row>

                    <v-divider />
                    <v-card-subtitle class="d-flex align-center font-weight-bold grey lighten-4">
                        병원 <v-spacer />
                        <location-view v-model="form.meta.location">
                            <template #activator="{ attrs, on }">
                                <v-btn class="my-n4" text color="blue darken-1" v-bind="attrs" v-on="on">
                                    병원 상세/수정 >
                                </v-btn>
                            </template>
                        </location-view>
                    </v-card-subtitle>
                    <v-divider />
                    <modify-request-items v-bind="{ form }" />
                    <v-divider />
                    <v-card-actions class="align-center grey lighten-4">
                        <v-switch v-model="form.meta.isDone" label="처리여부" inset dense color="primary" hide-details class="mx-3 mt-0 pt-0" />
                        <v-spacer />

                        <v-btn color="primary" @click="save">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </template>

            <template v-if="isCode(['review-report'])">
                <v-card class="my-4" tile>
                    <v-card-subtitle class="d-flex align-center font-weight-bold grey lighten-4">
                        신고자 <v-spacer />
                        <v-btn class="my-n4" text color="blue darken-1" :to="`/console/users/${form?.writer?._id}`">
                            사용자 상세 >
                        </v-btn>
                    </v-card-subtitle>
                    <v-divider />
                    <v-row v-if="$route.params._form" no-gutters>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.writer?.username" label="아이디" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.writer?.name" label="이름" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.writer?.nickname" label="닉네임" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.writer?.phone" label="연락처" persistent-placeholder hide-details readonly />
                        </v-col>
                    </v-row>

                    <v-divider />
                    <v-card-subtitle class="d-flex align-center font-weight-bold grey lighten-4">
                        신고상세
                    </v-card-subtitle>
                    <v-divider />
                    <v-row class="ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular">
                        <v-col cols="3" class="caption font-weight-bold">유형</v-col>
                        <v-col cols="auto" class="pa-0"> <v-divider vertical/></v-col>
                        <v-col>{{ form?.typeText }}</v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular">
                        <v-col cols="3" class="caption font-weight-bold">제목</v-col>
                        <v-col cols="auto" class="pa-0"> <v-divider vertical/></v-col>
                        <v-col>{{ form?.subject }}</v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular">
                        <v-col cols="3" class="caption font-weight-bold">내용</v-col>
                        <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col>
                        <v-col style="max-height: 200px; overflow-y:auto; white-space:pre-line"> {{ form?.content }} </v-col>
                    </v-row>

                    <v-divider />
                    <v-card-subtitle class="d-flex align-center font-weight-bold grey lighten-4">
                        후기내용 <v-spacer />
                        <review-view v-model="form.meta.review" readonly>
                            <template #activator="{ attrs, on }">
                                <v-btn class="my-n4" text color="blue darken-1" v-bind="attrs" v-on="on">
                                    후기 상세 >
                                </v-btn>
                            </template>
                        </review-view>
                    </v-card-subtitle>
                    <v-divider />
                    <v-row class="ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular">
                        <v-col cols="3" class="caption font-weight-bold">제목</v-col>
                        <v-col cols="auto" class="pa-0"> <v-divider vertical/></v-col>
                        <v-col>{{ form?.meta?.review?.subject }}</v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular">
                        <v-col cols="3" class="caption font-weight-bold">내용</v-col>
                        <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col>
                        <v-col style="max-height: 200px; overflow-y:auto;"> <div v-html="form?.meta?.review?.content" /> </v-col>
                    </v-row>

                    <v-divider />
                    <v-card-subtitle class="d-flex align-center font-weight-bold grey lighten-4">
                        후기병원 <v-spacer />
                        <location-view v-model="form.meta.location" readonly>
                            <template #activator="{ attrs, on }">
                                <v-btn class="my-n4" text color="blue darken-1" v-bind="attrs" v-on="on">
                                    병원 상세 >
                                </v-btn>
                            </template>
                        </location-view>
                    </v-card-subtitle>
                    <v-divider />
                    <v-row v-if="$route.params._form" no-gutters>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.meta?.location?.name" label="병원명" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.meta?.location?.phone" label="연락처" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.meta?.location?.email" label="이메일" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.meta?.location?.homepage" label="사이트" persistent-placeholder hide-details readonly />
                        </v-col>
                    </v-row>

                    <v-divider />
                    <v-card-subtitle class="d-flex align-center font-weight-bold grey lighten-4">
                        후기작성자 <v-spacer />
                        <v-btn class="my-n4" text color="blue darken-1" :to="`/console/users/${form?.meta?.review?.writer?._id}`">
                            사용자 상세 >
                        </v-btn>
                    </v-card-subtitle>
                    <v-divider />
                    <v-row v-if="$route.params._form" no-gutters>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.meta?.review?.writer?.username" label="아이디" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.meta?.review?.writer?.name" label="이름" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.meta?.review?.writer?.nickname" label="닉네임" persistent-placeholder hide-details readonly />
                        </v-col>
                        <v-col cols="auto"> <v-divider vertical /> </v-col>
                        <v-col class="pa-3">
                            <v-text-field :value="form?.meta?.review?.writer?.phone" label="연락처" persistent-placeholder hide-details readonly />
                        </v-col>
                    </v-row>

                    <v-divider />
                    <v-card-actions class="align-center grey lighten-4">
                        <v-switch v-model="form.meta.isDone" label="처리여부" inset dense color="primary" hide-details class="mx-3 mt-0 pt-0" />
                        <v-spacer />

                        <v-btn color="primary" @click="save">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </template>

            <v-card v-if="isCode(['interior-inquire', 'consignment-sale'])" class="mt-6">
                <v-card-title class="subtitle-1">답변</v-card-title>
                <v-divider />
                <v-card-text>
                    <naver-smarteditor v-model="form.reply" id="reply" rows="10"></naver-smarteditor>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn outlined color="grey" @click="form.reply = null">초기화</v-btn>
                    <v-spacer />
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-card-actions>
            </v-card>

            <v-row justify="center" v-if="!isCode(['interior-inquire', 'consignment-sale', 'location-modify-request', 'review-report'])">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

            <!-- <daum-postcode ref="daumPostcode" @change="changePostcode"></daum-postcode> -->
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mdiTagPlus } from "@mdi/js";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ReviewView from "@/components/console/eyes/review-view.vue";
import LocationView from "@/components/console/eyes/location-view.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ModifyRequestItems from "@/components/console/dumb/modify-request-items.vue";

export default {
    components: {
        ImagePopup,
        ReviewView,
        LocationView,
        DaumPostcode,
        NaverSmarteditor,
        ModifyRequestItems,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            form: {
                code: this.$route.query.code,
                category: undefined,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                createdAt: undefined,

                sequence: 1,

                storeName: undefined,
                storePhone: undefined,
                storeEmail: undefined,

                sido: undefined,
                gugun: undefined,
                area: undefined,
                postcode: undefined,
                address1: undefined,
                address2: undefined,
                lat: undefined,
                lng: undefined,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },

                collections: [],
                relativeProducts: [],
                portfolios: [],

                thumb: undefined,
            },
        };
    },
    computed: {
        headline() {
            if (this.form.code == "location-modify-request") return "정보수정요청 상세";
            if (this.form.code == "review-report") return "후기신고 상세";
            return "폼메일 상세";
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._form) {
                    var { form } = await api.console.forms.get({ _id: this.$route.params._form });
                    this.form = form;

                    if (form.thumb) this.form.thumb = await api.getResource(form.thumb);
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                var { form } = this.form._id ? await api.console.forms.put(this.form) : await api.console.forms.post(this.form);

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (this.form.thumb) await api.console.forms.postThumb(form, this.form.thumb);

                alert(this.form._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        isCode(codes = []) {
            return codes.includes(this.form.code);
        },
    },
};
</script>
