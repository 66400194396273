<template>
    <v-switch v-model="isOn" dense inset hide-details class="ma-0 pa-0" @click="save" />
</template>

<script>
import api from "@/api";
export default {
    props: { value: { type: Object, default: () => ({}) } },
    data: () => ({
        isOn: false,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "value.isOn"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.isOn = this.value.isOn;
        },
        async save() {
            const { value: { _id } = {}, isOn } = this;
            const { tag } = await api.console.eyes.tags.put({ _id, isOn });
            this.$emit("input", tag);
            alert("저장되었습니다.");
        },
    },
};
</script>

<style></style>
