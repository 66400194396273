var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('image-popup', {
    attrs: {
      "src": _vm.src,
      "size": _vm.size
    }
  })], 1), _c('v-col', [_c('v-file-input', _vm._b({
    attrs: {
      "label": _vm.label,
      "accept": "image/*"
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, 'v-file-input', _vm.inputAttrs, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }