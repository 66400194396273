<template>
    <v-container fluid fill-height class="console-banners">
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">
                        배너관리
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>
                
                <div class="caption primary--text">
                    * PC 배너 사이즈 1920*760(px) / 모바일 배너 사이즈 720*1180(px)
                </div>
                <v-data-table :headers="bannersHeaders" :items="banners" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:item.createdAt="{ item }">
                        {{ item.createdAt ? item.createdAt.toDate() : '' }}
                    </template>
                    <template v-slot:item.slides="{ item }">
                        <v-img v-for="(slide, index) in item.slides.filter((slide) => slide.image)" :key="index" :src="slide.image" max-width="96" max-height="96" contain class="d-inline-block"></v-img>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
                        <v-icon small @click="remove(item)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

            </v-responsive>
        </v-layout>
        
        <v-btn color="primary" fab fixed right bottom @click="create"><v-icon>mdi-pencil</v-icon></v-btn>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    created(){
        this.init();
    },
    data(){
        return {
            showsSearch: false,
            dialog: false,

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            bannersHeaders: [
                {
                    text: '코드',
                    width: 128,
                    align: 'center',
                    value: 'code'
                },
                {
                    text: '명칭',
                    align: 'center',
                    value: 'name'
                },
                {
                    text: '슬라이드',
                    align: 'start',
                    value: 'slides'
                },
                // {
                //     text: '언어',
                //     align: 'center',
                //     value: 'locale'
                // },
                {
                    text: '등록일자',
                    align: 'center',
                    width: 140,
                    value: 'createdAt'
                },
                {
                    text: 'actions',
                    align: 'center',
                    width: 100,
                    value: 'actions'
                }
            ],
            banners: [],
            editItem: null,
            defaultItem: {
                _id: null,
                code: null,
                name: null,
                slides: [
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                ]
            },

            images: new Array(10)
        };
    },
    methods: {
        async init(){
            try{
                await this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        async search(){
            try{
                var { summary, banners } = await api.console.banners.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    }
                });

                this.banners = banners;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        close() {
            this.dialog = false
        },

        create(){
            this.$router.push("/console/banners/create");
        },

        edit(banner) {
            this.$router.push(`/console/banners/${banner._id}`)
        },

        async save() {
            try{
                var { banner } = this.editItem._id ? await api.console.banners.put(this.editItem) : await api.console.banners.post(this.editItem);
                for(var i in this.images){
                    if(this.images[i]) await api.console.banners.postImage(banner._id, i, this.images[i]);
                }

                await this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
            this.dialog = false;
        },

        async remove(banner){
            if(confirm("배너를 삭제하시겠습니까?")){
                await api.console.banners.delete(banner);
                await this.search();
            }
        },

        async removeImage(banner, index){
            try{
                if(confirm("배너 이미지를 삭제하시겠습니까?")){
                    if(banner._id) await api.console.banners.deleteImage(banner._id, index);

                    this.images[index] = undefined;
                    banner.slides[index].image = undefined;
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){}
        }
    },
    watch: {
        dialog(){
            if(!this.dialog){
                this.editedItem = Object.assign({}, this.defaultItem)
            }
        }
    }
}
</script>

<style>
.console-banners-dialog .v-window__prev { margin-top: -50px !important; }
.console-banners-dialog .v-window__next { margin-top: -50px !important; }
.console-banners-dialog .v-carousel__controls { background-color: rgba(0,0,0,0.57) }
</style>
