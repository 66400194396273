var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "720",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "메인 관리"
    }
  }), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tabs', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "value": _vm.tab,
      "hide-slider": "",
      "active-class": "secondary white--text"
    }
  }, [_c('v-tab', {
    attrs: {
      "to": "/console/eyes/main-setting/tags"
    }
  }, [_vm._v("태그")]), _c('v-tab', {
    attrs: {
      "to": "/console/eyes/main-setting/counts"
    }
  }, [_vm._v("데이터 카운트")])], 1)], 1)], 1), _c('v-divider'), _c('v-tabs-items', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('main-tags'), _c('main-counts')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }