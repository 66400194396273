<template>
<v-container fluid fill-height class="console-terms">
    <v-layout v-if="setting" justify-center>
        <v-responsive max-width="1024px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">설정</v-col>
                <v-spacer></v-spacer>
                <v-col v-show="false" cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-card class="mt-4">
                <v-card-title class="subtitle-2 font-weight-bold">
                    <v-row align="center">
                        <v-col cols="auto pt-0">SMTP 설정 (이메일 전송 설정) 변경</v-col>
                        <v-col class="primary--text caption pt-0 pl-0">
                            "Google 계정 관리 -> 보안 -> 보안 수준이 낮은 앱의 액세스" 를 사용으로 변경
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="setting.smtp.username" label="발신자 아이디(gmail)" persistent-placeholder dense hide-details @input="save"></v-text-field>
                    <v-text-field v-model="setting.smtp.password" type="password" label="비밀번호" persistent-placeholder dense hide-details class="mt-6" @input="save"></v-text-field>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="py-0 subtitle-2 font-weight-bold">SEO(검색어 최적화 설정)</v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="setting.seo.title" label="타이틀: Title" persistent-placeholder dense hide-details class="mt-3" @input="meta('title').content = setting.seo.title; save();"></v-text-field>

                    <v-text-field v-model="meta('title').content" label="메타태그: Title" persistent-placeholder disabled dense hide-details class="mt-6" @input="save"></v-text-field>
                    <v-text-field v-model="meta('author').content" label="메타태그: Author" persistent-placeholder dense hide-details class="mt-6" @input="save"></v-text-field>
                    <v-text-field v-model="meta('subject').content" label="메타태그: Subject" persistent-placeholder dense hide-details class="mt-6" @input="save"></v-text-field>
                    <v-text-field v-model="meta('copyright').content" label="메타태그: Copyright" persistent-placeholder dense hide-details class="mt-6" @input="save"></v-text-field>
                    <v-textarea v-model="meta('description').content" label="메타태그: Description" persistent-placeholder dense hide-details class="mt-6" @input="save"></v-textarea>
                    <v-textarea v-model="meta('keywords').content" label="메타태그: Keywords" persistent-placeholder dense hide-details class="mt-6" @input="save"></v-textarea>
                </v-card-text>
            </v-card>

        </v-responsive>
    </v-layout>
</v-container>
</template>

<script>
import api from "@/api";
import banks from "@/assets/data/banks.json";
import vPasswordField from "@/components/plugins/vuetify/v-password-field.vue";

export default {
    components: {
        vPasswordField
    },
    created() {
        this.init();
    },
    data() {
        return {
            setting: null,
            banks
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },

        async search(){
            var { setting } = await api.console.setting.get();
            this.setting = setting;
        },

        meta(name){
            var meta = this.setting.seo.meta.find((meta) => meta.name == name);
            if(!meta){
                meta = { name, content: null };
                this.setting.seo.meta.push(meta);
            }
            return meta;
        },

        async save(){
            await api.console.setting.put(this.setting);
        },
    }
}
</script>
