var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.items, function (item) {
    return [_c('row-modify-request', _vm._b({
      key: `${item.label}-data`
    }, 'row-modify-request', item, false)), item !== _vm.items[_vm.items.length - 1] ? _c('v-divider', {
      key: `${item.label}-divider`
    }) : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }