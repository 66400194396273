<template>
    <v-edit-dialog large @close="sync" @save="save" cancel-text="취소" save-text="저장">
        <slot v-if="hasCustomActivator" />
        <v-switch v-else v-model="value.meta.isRewarded" dense inset readonly hide-details class="mr-n8 ml-4 my-0 pa-0" style="pointer-events:none" />
        <template #input>
            <v-row align="baseline">
                <v-col cols="auto" class="subtitle-2 font-weight-bold">{{ inputs.meta.isRewarded ? "보상지급" : "보상미지급" }}</v-col>
                <v-spacer />
                <v-col cols="auto"><v-switch v-model="inputs.meta.isRewarded" dense inset hide-details class="my-0 pa-0 mr-n4"/></v-col>
            </v-row>
            <v-divider class="mx-n4" style="position:absolute; width: 100%;" />
            <v-row>
                <v-col>
                    <v-textarea v-model="inputs.reward" label="비고" persistent-placeholder hide-details outlined dense />
                </v-col>
            </v-row>
            <v-divider class="mx-n4" style="position:absolute; width: 100%;" />
        </template>
    </v-edit-dialog>
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: Object, default: () => ({}) },
        hasCustomActivator: { type: Boolean, default: false },
    },
    data: () => ({
        inputs: {
            _id: null,
            meta: {
                isRewarded: false,
            },
            rewrad: null,
        },
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "value.reward"() {
            this.sync();
        },
        "value.meta.isRewarded"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.inputs = {
                ...this.value,
                meta: {
                    ...this.value?.meta,
                    isRewarded: this.value?.meta?.isRewarded || false,
                },
                reward: this.value?.reward || null,
            };
        },
        async save() {
            const { _id, meta, reward } = this.inputs;
            const { review } = await api.console.eyes.reviews.put({ _id, meta, reward });
            this.$emit("input", { ...this.value, meta: review.meta, reward: review.reward });
        },
    },
};
</script>

<style></style>
