<template>
    <list-search :showsSearch="showsSearch" @search="search">
        <v-row>
            <v-col lg="3" sm="6">
                <v-select label="시/도" placeholder="전체" v-model="filter['sido']" :items="sido" clearable v-bind="attrs_input" />
            </v-col>
            <v-col lg="3" sm="6">
                <v-select label="구/군" placeholder="전체" v-model="filter['gugun']" :items="gugun" clearable no-data-text="'시/도'를 먼저 선택하세요" v-bind="attrs_input" />
            </v-col>
            <v-col lg="3" sm="6">
                <v-select v-model="filter['searchKey']" :items="searchKeys" item-text="text" item-value="value" label="검색대상" v-bind="attrs_input" />
            </v-col>
            <v-col lg="3" sm="6">
                <v-text-field v-model="filter['searchValue']" label="검색어" v-bind="attrs_input" @keydown.enter="search" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import listSearch from "../dumb/list-search.vue";
import sidoGugun from "@/assets/data/sido-gugun.json";
const sido = sidoGugun.map(({ name } = {}) => name).flat();

const initFilter = () => ({
    sido: null,
    gugun: null,
    searchKey: null,
    searchValue: null,
});

const attrs_input = {
    dense: true,
    outlined: true,
    "hide-details": true,
    "persistent-placeholder": true,
};

const searchKeys = [
    { text: "전체", value: null },
    { text: "병원명", value: "name" },
    { text: "연락처", value: "phone" },
    { text: "이메일", value: "email" },
    { text: "주소", value: "address" },
];

export default {
    components: { listSearch },
    props: ["value", "showsSearch"],
    data() {
        return {
            filter: { ...initFilter(), ...(this.value || {}) },

            sido,
            searchKeys,

            attrs_input,
        };
    },
    computed: {
        gugun() {
            return (sidoGugun.find(({ name } = {}) => name == this.filter.sido)?.gugun || []).map(({ name } = {}) => name).flat();
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.filter = { ...initFilter(), ...(this.value || {}) };
        },
        search() {
            this.$emit("input", this.filter);
            this.$emit("search");
        },
    },
};
</script>

<style></style>
