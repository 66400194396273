var render = function render(){
  var _vm$value$tags$, _vm$value$tags$2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', {
    attrs: {
      "large": "",
      "cancel-text": "취소",
      "save-text": "저장"
    },
    on: {
      "close": _vm.sync,
      "save": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-row', [_c('v-col', [_c('autocomplete-tags', {
          attrs: {
            "label": "태그",
            "persistent-placeholder": "",
            "outlined": "",
            "hide-details": ""
          },
          model: {
            value: _vm.inputs._tags,
            callback: function ($$v) {
              _vm.$set(_vm.inputs, "_tags", $$v);
            },
            expression: "inputs._tags"
          }
        })], 1)], 1), _c('v-divider', {
          staticClass: "mx-n4",
          staticStyle: {
            "position": "absolute",
            "width": "100%"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.hasCustomActivator ? _vm._t("default") : _vm._e(), _vm.value.tags.length == 0 ? [_vm._v("-")] : _vm._e(), _vm.value.tags.length == 1 ? [_vm._v(_vm._s((_vm$value$tags$ = _vm.value.tags[0]) === null || _vm$value$tags$ === void 0 ? void 0 : _vm$value$tags$.tag))] : _vm._e(), _vm.value.tags.length > 1 ? [_vm._v(_vm._s((_vm$value$tags$2 = _vm.value.tags[0]) === null || _vm$value$tags$2 === void 0 ? void 0 : _vm$value$tags$2.tag) + " 외 " + _vm._s(_vm.value.tags.length - 1))] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }