<template>
    <v-dialog v-model="showsDialog" max-width="1280" width="85%" dark overlay-color="black" overlay-opacity="0.65" content-class="elevation-0" no-click-animation>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, setIndex }" />
        </template>
        <v-sheet max-height="90vh" color="transparent" class="px-3">
            <v-row align="center">
                <v-col cols="auto">
                    <v-btn x-large fab text @click="goPrev" :disabled="disable_prev">
                        <v-icon x-large>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-col>
                <v-col>
                    <v-tabs-items :value="selectedIndex" style="background: transparent">
                        <v-tab-item v-for="(image, index) in images" :key="image" :value="index" style="background: transparent">
                            <template v-if="disableLink">
                                <v-img :src="image" :aspect-ratio="1 / 1" width="100%" height="70vh" contain style="background: transparent" />
                            </template>
                            <a v-else :href="image" target="_blank" rel="noopener noreferrer">
                                <v-img :src="image" :aspect-ratio="1 / 1" width="100%" height="70vh" contain style="background: transparent" />
                            </a>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
                <v-col cols="auto">
                    <v-btn x-large fab text @click="goNext" :disabled="disable_next">
                        <v-icon x-large>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center" align="center">
                <v-col cols="6">
                    <v-row>
                        <v-col cols="2" v-for="(image, index) in images" :key="image" class="pa-1" style="position: relative" @mouseenter="setIndex(image)">
                            <v-fade-transition>
                                <v-overlay v-show="selectedIndex == index" absolute z-index="0" color="white" opacity="1" />
                            </v-fade-transition>
                            <v-img :src="image" :aspect-ratio="1 / 1" width="100%" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-fade-transition hide-on-leave>
                <v-btn v-show="showsCloseBtn" absolute top right fab x-large tile class="mr-n4 mt-9" @click="showsDialog = false">
                    <v-icon x-large>mdi-close</v-icon>
                </v-btn>
            </v-fade-transition>
        </v-sheet>
    </v-dialog>
</template>

<script>
export default {
    props: {
        images: { type: Array, default: () => [] },
        disableLink: { type: Boolean, default: false },
    },
    data: () => ({
        showsDialog: false,
        showsCloseBtn: false,
        selectedIndex: 0,
    }),
    computed: {
        disable_prev() {
            return this.selectedIndex < 1;
        },
        disable_next() {
            return this.selectedIndex >= this.images.length - 1;
        },
    },
    watch: {
        showsDialog() {
            if (this.showsDialog) setTimeout(() => (this.showsCloseBtn = true), 1000);
            else this.showsCloseBtn = false;
        },
    },
    methods: {
        setIndex(image) {
            this.selectedIndex = this.images.findIndex((item) => item == image);
        },
        goPrev() {
            if (this.selectedIndex > 0) this.selectedIndex -= 1;
        },
        goNext() {
            if (this.selectedIndex < this.images.length - 1) this.selectedIndex += 1;
        },
    },
};
</script>

<style>
</style>