<template>
    <v-autocomplete v-model="$data._tags" :items="tags" item-text="tag" item-value="_id" v-bind="$attrs" multiple />
</template>

<script>
import api from "@/api";
import { TAG_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        _tags: [],
        tags: [],
    }),
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        "$data._tags"(_tags) {
            this.$emit("input", _tags);
        },
    },
    methods: {
        sync() {
            this.$data._tags = this.value;
        },
        async init() {
            this.tags = (await api.console.eyes.tags.gets({ params: { type: TAG_TYPES.custom.value } }))?.tags || [];
        },
    },
};
</script>

<style></style>
