var render = function render(){
  var _ref, _ref$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', {
    attrs: {
      "large": "",
      "cancel-text": "취소",
      "save-text": "저장"
    },
    on: {
      "close": _vm.sync,
      "save": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-row', [_c('v-col', [_c('v-text-field', {
          attrs: {
            "type": "number",
            "label": "추천수",
            "persistent-placeholder": "",
            "outlined": "",
            "hide-details": "",
            "dense": ""
          },
          model: {
            value: _vm.inputs.meta.likes,
            callback: function ($$v) {
              _vm.$set(_vm.inputs.meta, "likes", $$v);
            },
            expression: "inputs.meta.likes"
          }
        })], 1)], 1), _c('v-divider', {
          staticClass: "mx-n4",
          staticStyle: {
            "position": "absolute",
            "width": "100%"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.hasCustomActivator ? _vm._t("default") : _vm._e(), _vm._v(" " + _vm._s((_ref = _vm.value.meta.likes || 0) === null || _ref === void 0 ? void 0 : (_ref$format = _ref.format) === null || _ref$format === void 0 ? void 0 : _ref$format.call(_ref)) + " 건 ")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }