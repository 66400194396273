var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular"
  }, [_c('v-col', {
    staticClass: "caption font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.label))]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), !!_vm.from ? _c('v-col', [_vm._v(" " + _vm._s(_vm.from) + " ")]) : _c('v-col', {
    staticClass: "grey--text text--lighten-1"
  }, [_vm._v(" 데이터 없음 ")]), _c('v-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": !_vm.to ? 'transparent' : 'warning'
    }
  }, [_vm._v(" mdi-chevron-right ")])], 1), !!_vm.to ? _c('v-col', [_vm._v(" " + _vm._s(_vm.to) + " ")]) : _c('v-col', {
    staticClass: "grey--text text--lighten-1"
  }, [_vm._v(" 데이터 없음 ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }