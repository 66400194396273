<template>
    <div>
        <template v-for="item in items">
            <row-modify-request :key="`${item.label}-data`" v-bind="item" />
            <v-divider v-if="item !== items[items.length - 1]" :key="`${item.label}-divider`" />
        </template>
    </div>
</template>

<script>
import RowModifyRequest from "./row-modify-request.vue";

const formatAddress = ({ postcode, address1, address2 } = {}) => {
    if (!postcode && !address1 && !address2) return undefined;

    let address = "";
    if (postcode) address += `[${postcode}] `;
    if (address1) address += `${address1} `;
    if (address2) address += `${address2} `;
    return address.trim();
};

export default {
    components: {
        RowModifyRequest,
    },
    props: {
        form: { type: Object, default: () => ({}) },
    },
    computed: {
        items() {
            const { meta: { location, ...meta } = {} } = this.form || {};

            return [
                { label: "병원명", from: location?.name, to: meta?.name },
                { label: "연락처", from: location?.phone, to: meta?.phone },
                { label: "이메일", from: location?.email, to: meta?.email },
                { label: "사이트", from: location?.homepage, to: meta?.homepage },
                { label: "주소", from: formatAddress(location), to: formatAddress(meta) },
            ];
        },
    },
};
</script>

<style></style>
