var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._b({
    attrs: {
      "label": "태그",
      "prefix": "＃",
      "items": _vm.items,
      "item-value": "value"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.text) + " - " + _vm._s(item.address1) + " " + _vm._s(item.address2) + " ")];
      }
    }]),
    model: {
      value: _vm.$data._location,
      callback: function ($$v) {
        _vm.$set(_vm.$data, "_location", $$v);
      },
      expression: "$data._location"
    }
  }, 'v-autocomplete', _vm.attrs_input, false));

}
var staticRenderFns = []

export { render, staticRenderFns }