var render = function render(){
  var _vm$form, _vm$form$createdAt, _vm$form$createdAt$to, _vm$form2, _vm$form3, _vm$form3$meta, _vm$form4, _vm$form4$meta, _vm$form5, _vm$form5$meta, _ref, _vm$form6, _vm$form7, _vm$form8, _vm$form8$meta, _vm$form9, _vm$form9$meta, _vm$form10, _vm$form10$meta, _vm$form11, _vm$form11$meta, _vm$form12, _vm$form12$meta, _vm$form13, _vm$form13$meta, _vm$form14, _vm$form14$meta, _vm$form15, _vm$form15$meta, _vm$form$meta$price_b, _vm$form$meta$price_b2, _vm$form16, _vm$form16$meta, _vm$form17, _vm$form17$meta, _vm$form$meta$price_a, _vm$form$meta$price_a2, _vm$form18, _vm$form18$meta, _vm$form19, _vm$form19$meta, _vm$form20, _vm$form20$writer, _vm$form21, _vm$form21$writer, _vm$form22, _vm$form22$writer, _vm$form23, _vm$form23$writer, _vm$form24, _vm$form24$meta, _vm$form25, _vm$form25$writer, _vm$form26, _vm$form26$writer, _vm$form27, _vm$form27$writer, _vm$form28, _vm$form28$writer, _vm$form29, _vm$form29$writer, _vm$form30, _vm$form31, _vm$form32, _vm$form33, _vm$form33$meta, _vm$form33$meta$revie, _vm$form34, _vm$form34$meta, _vm$form34$meta$revie, _vm$form35, _vm$form35$meta, _vm$form35$meta$locat, _vm$form36, _vm$form36$meta, _vm$form36$meta$locat, _vm$form37, _vm$form37$meta, _vm$form37$meta$locat, _vm$form38, _vm$form38$meta, _vm$form38$meta$locat, _vm$form39, _vm$form39$meta, _vm$form39$meta$revie, _vm$form39$meta$revie2, _vm$form40, _vm$form40$meta, _vm$form40$meta$revie, _vm$form40$meta$revie2, _vm$form41, _vm$form41$meta, _vm$form41$meta$revie, _vm$form41$meta$revie2, _vm$form42, _vm$form42$meta, _vm$form42$meta$revie, _vm$form42$meta$revie2, _vm$form43, _vm$form43$meta, _vm$form43$meta$revie, _vm$form43$meta$revie2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-1",
    attrs: {
      "max-width": "880",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "no-gutters": "",
      "align": "baseline"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.headline))]), _c('v-spacer'), _vm.isCode(['location-modify-request']) ? _c('v-col', {
    staticClass: "caption",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("요청일: " + _vm._s(((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$createdAt = _vm$form.createdAt) === null || _vm$form$createdAt === void 0 ? void 0 : (_vm$form$createdAt$to = _vm$form$createdAt.toDateTime) === null || _vm$form$createdAt$to === void 0 ? void 0 : _vm$form$createdAt$to.call(_vm$form$createdAt)) || ((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : _vm$form2.createdAt) || ""))]) : _vm._e()], 1), !_vm.isCode(['consignment-sale', 'location-modify-request', 'review-report']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.form.writer || {}).name,
      "label": "작성자",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: (_vm.form.writer || {}).phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer || {}, "phone", $$v);
      },
      expression: "(form.writer || {}).phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이메일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: (_vm.form.writer || {}).email,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer || {}, "email", $$v);
      },
      expression: "(form.writer || {}).email"
    }
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), !_vm.isCode(['consignment-sale', 'location-modify-request', 'review-report']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "유형",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.type,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "type", $$v);
      },
      expression: "form.meta.type"
    }
  })], 1)], 1) : _vm._e(), !_vm.isCode(['consignment-sale', 'location-modify-request', 'review-report']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-divider'), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1) : _vm._e(), _vm.isCode(['consignment-sale']) ? [_c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("위탁 신청인 정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form3 = _vm.form) === null || _vm$form3 === void 0 ? void 0 : (_vm$form3$meta = _vm$form3.meta) === null || _vm$form3$meta === void 0 ? void 0 : _vm$form3$meta.name,
      "label": "작성자",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form4 = _vm.form) === null || _vm$form4 === void 0 ? void 0 : (_vm$form4$meta = _vm$form4.meta) === null || _vm$form4$meta === void 0 ? void 0 : _vm$form4$meta.phone,
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form5 = _vm.form) === null || _vm$form5 === void 0 ? void 0 : (_vm$form5$meta = _vm$form5.meta) === null || _vm$form5$meta === void 0 ? void 0 : _vm$form5$meta.email,
      "label": "이메일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("위탁 작품 정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("이미지")]), _c('v-row', [((_ref = ((_vm$form6 = _vm.form) === null || _vm$form6 === void 0 ? void 0 : _vm$form6.files) || []) === null || _ref === void 0 ? void 0 : _ref.length) < 1 ? _c('v-col', [_vm._v(" 이미지가 없습니다. ")]) : _vm._e(), _vm._l(((_vm$form7 = _vm.form) === null || _vm$form7 === void 0 ? void 0 : _vm$form7.files) || [], function (file) {
    return _c('v-col', {
      key: file._id,
      attrs: {
        "cols": "2"
      }
    }, [_c('image-popup', {
      attrs: {
        "src": file.url,
        "aspect-ratio": 1 / 1,
        "width": "100%"
      }
    })], 1);
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form8 = _vm.form) === null || _vm$form8 === void 0 ? void 0 : (_vm$form8$meta = _vm$form8.meta) === null || _vm$form8$meta === void 0 ? void 0 : _vm$form8$meta.author,
      "label": "작가명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form9 = _vm.form) === null || _vm$form9 === void 0 ? void 0 : (_vm$form9$meta = _vm$form9.meta) === null || _vm$form9$meta === void 0 ? void 0 : _vm$form9$meta.subject,
      "label": "작품명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form10 = _vm.form) === null || _vm$form10 === void 0 ? void 0 : (_vm$form10$meta = _vm$form10.meta) === null || _vm$form10$meta === void 0 ? void 0 : _vm$form10$meta.material,
      "label": "작품의 재료",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form11 = _vm.form) === null || _vm$form11 === void 0 ? void 0 : (_vm$form11$meta = _vm$form11.meta) === null || _vm$form11$meta === void 0 ? void 0 : _vm$form11$meta.dimension,
      "label": "작품의 크기",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form12 = _vm.form) === null || _vm$form12 === void 0 ? void 0 : (_vm$form12$meta = _vm$form12.meta) === null || _vm$form12$meta === void 0 ? void 0 : _vm$form12$meta.edition,
      "label": "Edition",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form13 = _vm.form) === null || _vm$form13 === void 0 ? void 0 : (_vm$form13$meta = _vm$form13.meta) === null || _vm$form13$meta === void 0 ? void 0 : _vm$form13$meta.year,
      "label": "제작년도(추정)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "value": (_vm$form14 = _vm.form) === null || _vm$form14 === void 0 ? void 0 : (_vm$form14$meta = _vm$form14.meta) === null || _vm$form14$meta === void 0 ? void 0 : _vm$form14$meta.content,
      "label": "작품설명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "value": (_vm$form15 = _vm.form) === null || _vm$form15 === void 0 ? void 0 : (_vm$form15$meta = _vm$form15.meta) === null || _vm$form15$meta === void 0 ? void 0 : _vm$form15$meta.etc,
      "label": "기타",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": ((_vm$form$meta$price_b = +((_vm$form16 = _vm.form) === null || _vm$form16 === void 0 ? void 0 : (_vm$form16$meta = _vm$form16.meta) === null || _vm$form16$meta === void 0 ? void 0 : _vm$form16$meta.price_bought)) === null || _vm$form$meta$price_b === void 0 ? void 0 : (_vm$form$meta$price_b2 = _vm$form$meta$price_b.format) === null || _vm$form$meta$price_b2 === void 0 ? void 0 : _vm$form$meta$price_b2.call(_vm$form$meta$price_b)) || ((_vm$form17 = _vm.form) === null || _vm$form17 === void 0 ? void 0 : (_vm$form17$meta = _vm$form17.meta) === null || _vm$form17$meta === void 0 ? void 0 : _vm$form17$meta.price_bought),
      "label": "구입가(KRW)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": ((_vm$form$meta$price_a = +((_vm$form18 = _vm.form) === null || _vm$form18 === void 0 ? void 0 : (_vm$form18$meta = _vm$form18.meta) === null || _vm$form18$meta === void 0 ? void 0 : _vm$form18$meta.price_asking)) === null || _vm$form$meta$price_a === void 0 ? void 0 : (_vm$form$meta$price_a2 = _vm$form$meta$price_a.format) === null || _vm$form$meta$price_a2 === void 0 ? void 0 : _vm$form$meta$price_a2.call(_vm$form$meta$price_a)) || ((_vm$form19 = _vm.form) === null || _vm$form19 === void 0 ? void 0 : (_vm$form19$meta = _vm$form19.meta) === null || _vm$form19$meta === void 0 ? void 0 : _vm$form19$meta.price_asking),
      "label": "희망가(KRW)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1) : _vm._e()], 1)], 1)] : _vm._e(), _vm.isCode(['location-modify-request']) ? [_c('v-card', {
    staticClass: "my-4",
    attrs: {
      "tile": ""
    }
  }, [_c('v-card-subtitle', {
    staticClass: "d-flex align-center font-weight-bold grey lighten-4"
  }, [_vm._v(" 요청인 "), _c('v-spacer'), _c('v-btn', {
    staticClass: "my-n4",
    attrs: {
      "text": "",
      "color": "blue darken-1",
      "to": `/console/users/${(_vm$form20 = _vm.form) === null || _vm$form20 === void 0 ? void 0 : (_vm$form20$writer = _vm$form20.writer) === null || _vm$form20$writer === void 0 ? void 0 : _vm$form20$writer._id}`
    }
  }, [_vm._v(" 사용자 상세 > ")])], 1), _c('v-divider'), _vm.$route.params._form ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form21 = _vm.form) === null || _vm$form21 === void 0 ? void 0 : (_vm$form21$writer = _vm$form21.writer) === null || _vm$form21$writer === void 0 ? void 0 : _vm$form21$writer.username,
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form22 = _vm.form) === null || _vm$form22 === void 0 ? void 0 : (_vm$form22$writer = _vm$form22.writer) === null || _vm$form22$writer === void 0 ? void 0 : _vm$form22$writer.name,
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form23 = _vm.form) === null || _vm$form23 === void 0 ? void 0 : (_vm$form23$writer = _vm$form23.writer) === null || _vm$form23$writer === void 0 ? void 0 : _vm$form23$writer.nickname,
      "label": "닉네임",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form24 = _vm.form) === null || _vm$form24 === void 0 ? void 0 : (_vm$form24$meta = _vm$form24.meta) === null || _vm$form24$meta === void 0 ? void 0 : _vm$form24$meta.phone,
      "label": "연락처",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "d-flex align-center font-weight-bold grey lighten-4"
  }, [_vm._v(" 병원 "), _c('v-spacer'), _c('location-view', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "my-n4",
          attrs: {
            "text": "",
            "color": "blue darken-1"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 병원 상세/수정 > ")])];
      }
    }], null, false, 2536331837),
    model: {
      value: _vm.form.meta.location,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "location", $$v);
      },
      expression: "form.meta.location"
    }
  })], 1), _c('v-divider'), _c('modify-request-items', _vm._b({}, 'modify-request-items', {
    form: _vm.form
  }, false)), _c('v-divider'), _c('v-card-actions', {
    staticClass: "align-center grey lighten-4"
  }, [_c('v-switch', {
    staticClass: "mx-3 mt-0 pt-0",
    attrs: {
      "label": "처리여부",
      "inset": "",
      "dense": "",
      "color": "primary",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.isDone,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "isDone", $$v);
      },
      expression: "form.meta.isDone"
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)] : _vm._e(), _vm.isCode(['review-report']) ? [_c('v-card', {
    staticClass: "my-4",
    attrs: {
      "tile": ""
    }
  }, [_c('v-card-subtitle', {
    staticClass: "d-flex align-center font-weight-bold grey lighten-4"
  }, [_vm._v(" 신고자 "), _c('v-spacer'), _c('v-btn', {
    staticClass: "my-n4",
    attrs: {
      "text": "",
      "color": "blue darken-1",
      "to": `/console/users/${(_vm$form25 = _vm.form) === null || _vm$form25 === void 0 ? void 0 : (_vm$form25$writer = _vm$form25.writer) === null || _vm$form25$writer === void 0 ? void 0 : _vm$form25$writer._id}`
    }
  }, [_vm._v(" 사용자 상세 > ")])], 1), _c('v-divider'), _vm.$route.params._form ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form26 = _vm.form) === null || _vm$form26 === void 0 ? void 0 : (_vm$form26$writer = _vm$form26.writer) === null || _vm$form26$writer === void 0 ? void 0 : _vm$form26$writer.username,
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form27 = _vm.form) === null || _vm$form27 === void 0 ? void 0 : (_vm$form27$writer = _vm$form27.writer) === null || _vm$form27$writer === void 0 ? void 0 : _vm$form27$writer.name,
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form28 = _vm.form) === null || _vm$form28 === void 0 ? void 0 : (_vm$form28$writer = _vm$form28.writer) === null || _vm$form28$writer === void 0 ? void 0 : _vm$form28$writer.nickname,
      "label": "닉네임",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form29 = _vm.form) === null || _vm$form29 === void 0 ? void 0 : (_vm$form29$writer = _vm$form29.writer) === null || _vm$form29$writer === void 0 ? void 0 : _vm$form29$writer.phone,
      "label": "연락처",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "d-flex align-center font-weight-bold grey lighten-4"
  }, [_vm._v(" 신고상세 ")]), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular"
  }, [_c('v-col', {
    staticClass: "caption font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("유형")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$form30 = _vm.form) === null || _vm$form30 === void 0 ? void 0 : _vm$form30.typeText))])], 1), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular"
  }, [_c('v-col', {
    staticClass: "caption font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("제목")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$form31 = _vm.form) === null || _vm$form31 === void 0 ? void 0 : _vm$form31.subject))])], 1), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular"
  }, [_c('v-col', {
    staticClass: "caption font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("내용")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticStyle: {
      "max-height": "200px",
      "overflow-y": "auto",
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s((_vm$form32 = _vm.form) === null || _vm$form32 === void 0 ? void 0 : _vm$form32.content) + " ")])], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "d-flex align-center font-weight-bold grey lighten-4"
  }, [_vm._v(" 후기내용 "), _c('v-spacer'), _c('review-view', {
    attrs: {
      "readonly": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var attrs = _ref3.attrs,
          on = _ref3.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "my-n4",
          attrs: {
            "text": "",
            "color": "blue darken-1"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 후기 상세 > ")])];
      }
    }], null, false, 1431902346),
    model: {
      value: _vm.form.meta.review,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "review", $$v);
      },
      expression: "form.meta.review"
    }
  })], 1), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular"
  }, [_c('v-col', {
    staticClass: "caption font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("제목")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$form33 = _vm.form) === null || _vm$form33 === void 0 ? void 0 : (_vm$form33$meta = _vm$form33.meta) === null || _vm$form33$meta === void 0 ? void 0 : (_vm$form33$meta$revie = _vm$form33$meta.review) === null || _vm$form33$meta$revie === void 0 ? void 0 : _vm$form33$meta$revie.subject))])], 1), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular"
  }, [_c('v-col', {
    staticClass: "caption font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("내용")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticStyle: {
      "max-height": "200px",
      "overflow-y": "auto"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$form34 = _vm.form) === null || _vm$form34 === void 0 ? void 0 : (_vm$form34$meta = _vm$form34.meta) === null || _vm$form34$meta === void 0 ? void 0 : (_vm$form34$meta$revie = _vm$form34$meta.review) === null || _vm$form34$meta$revie === void 0 ? void 0 : _vm$form34$meta$revie.content)
    }
  })])], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "d-flex align-center font-weight-bold grey lighten-4"
  }, [_vm._v(" 후기병원 "), _c('v-spacer'), _c('location-view', {
    attrs: {
      "readonly": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var attrs = _ref4.attrs,
          on = _ref4.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "my-n4",
          attrs: {
            "text": "",
            "color": "blue darken-1"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 병원 상세 > ")])];
      }
    }], null, false, 3572958911),
    model: {
      value: _vm.form.meta.location,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "location", $$v);
      },
      expression: "form.meta.location"
    }
  })], 1), _c('v-divider'), _vm.$route.params._form ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form35 = _vm.form) === null || _vm$form35 === void 0 ? void 0 : (_vm$form35$meta = _vm$form35.meta) === null || _vm$form35$meta === void 0 ? void 0 : (_vm$form35$meta$locat = _vm$form35$meta.location) === null || _vm$form35$meta$locat === void 0 ? void 0 : _vm$form35$meta$locat.name,
      "label": "병원명",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form36 = _vm.form) === null || _vm$form36 === void 0 ? void 0 : (_vm$form36$meta = _vm$form36.meta) === null || _vm$form36$meta === void 0 ? void 0 : (_vm$form36$meta$locat = _vm$form36$meta.location) === null || _vm$form36$meta$locat === void 0 ? void 0 : _vm$form36$meta$locat.phone,
      "label": "연락처",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form37 = _vm.form) === null || _vm$form37 === void 0 ? void 0 : (_vm$form37$meta = _vm$form37.meta) === null || _vm$form37$meta === void 0 ? void 0 : (_vm$form37$meta$locat = _vm$form37$meta.location) === null || _vm$form37$meta$locat === void 0 ? void 0 : _vm$form37$meta$locat.email,
      "label": "이메일",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form38 = _vm.form) === null || _vm$form38 === void 0 ? void 0 : (_vm$form38$meta = _vm$form38.meta) === null || _vm$form38$meta === void 0 ? void 0 : (_vm$form38$meta$locat = _vm$form38$meta.location) === null || _vm$form38$meta$locat === void 0 ? void 0 : _vm$form38$meta$locat.homepage,
      "label": "사이트",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "d-flex align-center font-weight-bold grey lighten-4"
  }, [_vm._v(" 후기작성자 "), _c('v-spacer'), _c('v-btn', {
    staticClass: "my-n4",
    attrs: {
      "text": "",
      "color": "blue darken-1",
      "to": `/console/users/${(_vm$form39 = _vm.form) === null || _vm$form39 === void 0 ? void 0 : (_vm$form39$meta = _vm$form39.meta) === null || _vm$form39$meta === void 0 ? void 0 : (_vm$form39$meta$revie = _vm$form39$meta.review) === null || _vm$form39$meta$revie === void 0 ? void 0 : (_vm$form39$meta$revie2 = _vm$form39$meta$revie.writer) === null || _vm$form39$meta$revie2 === void 0 ? void 0 : _vm$form39$meta$revie2._id}`
    }
  }, [_vm._v(" 사용자 상세 > ")])], 1), _c('v-divider'), _vm.$route.params._form ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form40 = _vm.form) === null || _vm$form40 === void 0 ? void 0 : (_vm$form40$meta = _vm$form40.meta) === null || _vm$form40$meta === void 0 ? void 0 : (_vm$form40$meta$revie = _vm$form40$meta.review) === null || _vm$form40$meta$revie === void 0 ? void 0 : (_vm$form40$meta$revie2 = _vm$form40$meta$revie.writer) === null || _vm$form40$meta$revie2 === void 0 ? void 0 : _vm$form40$meta$revie2.username,
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form41 = _vm.form) === null || _vm$form41 === void 0 ? void 0 : (_vm$form41$meta = _vm$form41.meta) === null || _vm$form41$meta === void 0 ? void 0 : (_vm$form41$meta$revie = _vm$form41$meta.review) === null || _vm$form41$meta$revie === void 0 ? void 0 : (_vm$form41$meta$revie2 = _vm$form41$meta$revie.writer) === null || _vm$form41$meta$revie2 === void 0 ? void 0 : _vm$form41$meta$revie2.name,
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form42 = _vm.form) === null || _vm$form42 === void 0 ? void 0 : (_vm$form42$meta = _vm$form42.meta) === null || _vm$form42$meta === void 0 ? void 0 : (_vm$form42$meta$revie = _vm$form42$meta.review) === null || _vm$form42$meta$revie === void 0 ? void 0 : (_vm$form42$meta$revie2 = _vm$form42$meta$revie.writer) === null || _vm$form42$meta$revie2 === void 0 ? void 0 : _vm$form42$meta$revie2.nickname,
      "label": "닉네임",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form43 = _vm.form) === null || _vm$form43 === void 0 ? void 0 : (_vm$form43$meta = _vm$form43.meta) === null || _vm$form43$meta === void 0 ? void 0 : (_vm$form43$meta$revie = _vm$form43$meta.review) === null || _vm$form43$meta$revie === void 0 ? void 0 : (_vm$form43$meta$revie2 = _vm$form43$meta$revie.writer) === null || _vm$form43$meta$revie2 === void 0 ? void 0 : _vm$form43$meta$revie2.phone,
      "label": "연락처",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', {
    staticClass: "align-center grey lighten-4"
  }, [_c('v-switch', {
    staticClass: "mx-3 mt-0 pt-0",
    attrs: {
      "label": "처리여부",
      "inset": "",
      "dense": "",
      "color": "primary",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.isDone,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "isDone", $$v);
      },
      expression: "form.meta.isDone"
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)] : _vm._e(), _vm.isCode(['interior-inquire', 'consignment-sale']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("답변")]), _c('v-divider'), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "reply",
      "rows": "10"
    },
    model: {
      value: _vm.form.reply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reply", $$v);
      },
      expression: "form.reply"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        _vm.form.reply = null;
      }
    }
  }, [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e(), !_vm.isCode(['interior-inquire', 'consignment-sale', 'location-modify-request', 'review-report']) ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }