<template>
    <v-expand-transition>
        <div v-show="showsSearch">
            <v-card flat class="mb-4">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text>
                    <slot />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text @click="$emit('search')"> <v-icon small>mdi-magnify</v-icon> 검색 </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-expand-transition>
</template>

<script>
export default {
    props: ["showsSearch"],
};
</script>

<style></style>
