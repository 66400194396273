<template>
    <v-dialog v-model="shows" max-width="1080" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-if="isLoaded" height="100%">
            <v-card-title>
                리뷰 상세
                <v-spacer />
                <v-icon @click="shows = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="height: calc(90vh - 120px); overflow-y: auto">
                <v-row no-gutters style="min-height: calc(100% - 3px)">
                    <v-col>
                        <v-card-subtitle class="grey lighten-4">작성자 정보</v-card-subtitle>
                        <v-divider />
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">이름</v-col>
                            <v-col cols="auto">{{ inputs?.writer?.name }}</v-col>
                        </v-row>
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">닉네임</v-col>
                            <v-col cols="auto">{{ inputs?.writer?.nickname }}</v-col>
                        </v-row>
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">연락처</v-col>
                            <v-col cols="auto">{{ inputs?.writer?.phone?.phoneNumberFormat?.() }}</v-col>
                        </v-row>
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">이메일</v-col>
                            <v-col cols="auto">{{ inputs?.writer?.email }}</v-col>
                        </v-row>
                        <v-divider />
                        <v-card-subtitle class="grey lighten-4">기본정보</v-card-subtitle>
                        <v-divider />
                        <v-row class="ma-0 pa-2">
                            <v-col cols="12" class="pa-2"> <review-write-clinic v-model="inputs" label="병원" v-bind="attrs_input" /> </v-col>
                            <v-col cols="12" class="pa-2"> <v-text-field v-model="inputs.doctor" label="의사" v-bind="attrs_input" /> </v-col>
                            <v-col cols="12" class="pa-2"> <v-select v-model="inputs.services" label="진료" :items="services" multiple v-bind="attrs_input" /> </v-col>
                            <v-col cols="12" class="pa-2"> <v-currency-field v-model="inputs.charges.total" label="금액" suffix="원" v-bind="attrs_input" /> </v-col>
                        </v-row>
                        <v-divider />
                        <review-view-stars v-model="inputs" />
                    </v-col>
                    <v-col cols="auto">
                        <v-divider vertical />
                    </v-col>
                    <v-col cols="" md="8">
                        <v-card-subtitle class="grey lighten-4 d-flex align-center justify-space-between"> 리뷰 상세정보 </v-card-subtitle>
                        <v-divider />
                        <v-row class="ma-0 pa-2">
                            <v-col cols="12" class="pa-2"> <v-text-field v-model="inputs.subject" label="제목" v-bind="attrs_input" /> </v-col>
                            <v-col cols="12" class="pa-2"> <v-textarea v-model="inputs.content" label="내용" v-bind="attrs_input" /> </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="ma-0 pa-2">
                            <v-col cols="12" class="pa-2">이미지</v-col>
                            <v-col cols="12" class="pa-2"> <images-input v-model="inputs.photos" /> </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="ma-0 pa-2">
                            <v-col cols="12" class="pa-2">영수증</v-col>
                            <v-col cols="12" class="pa-2">
                                <review-form-receipts v-model="inputs.receipts" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="d-flex align-center subtitle-2">
                <i class="mx-1" v-if="inputs.meta.isRewarded"> * 보상 지급 완료 </i>
                <i class="mx-1" v-else> * 보상 미지급 </i>
                <v-spacer />
                <review-edit-reward v-model="inputs" hasCustomActivator @input="$emit('input', inputs)">
                    <v-btn large color="secondary" class="px-5" v-if="inputs.meta.isRewarded">보상 지급 확인 및 수정</v-btn>
                    <v-btn large color="secondary" class="px-5" v-else>보상 지급</v-btn>
                </review-edit-reward>
                <v-btn large color="primary" class="ml-2 px-5" @click="save">수정하기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";

import { getGeolocation } from "@/plugins/getGeolocation";
import { attrs_input, SERVICES } from "@/assets/variables";

import ImagesInput from "../dumb/images-input.vue";
import ReviewViewStars from "./review-view-stars.vue";
import ReviewEditReward from "./review-edit-reward.vue";
import ReviewWriteClinic from "@/components/client/reviews/review-write-clinic.vue";
import ReviewFormReceipts from "./review-form-receipts.vue";

const initReview = () => ({
    writer: {
        name: null,
        phone: null,
        email: null,
        nickname: null,
    },
    location: {
        areaName: null,
        name: null,
    },
    georeview: {
        type: "Point",
        coordinates: null,
    },
    photos: [],
});

const services = Object.values(SERVICES);

export default {
    components: {
        ImagesInput,
        ReviewViewStars,
        ReviewEditReward,
        ReviewWriteClinic,
        ReviewFormReceipts,
    },
    props: {
        value: { type: Object, default: initReview },
    },
    data: () => ({
        shows: false,
        isLoaded: false,

        inputs: {},
        receipts: [],

        services,
        attrs_input,
    }),
    computed: {
        _review() {
            return this.value?._id;
        },
        isCreate() {
            return !this._review;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            this.isLoaded = false;
            if (this.isCreate) {
                this.inputs = { ...initReview() };
            } else {
                const { review } = await api.console.eyes.reviews.get({ _id: this._review });
                this.inputs = review;
                this.receipts = JSON.parse(JSON.stringify(review?.receipts || []));
            }
            this.isLoaded = true;
        },

        validate() {},

        async save() {
            this.validate();

            let { _photos, photos, receipts, ...inputs } = this.inputs;
            inputs.geolocation = await getGeolocation(inputs.address1);

            // POST / PUT document
            const { post, put } = api.console.eyes.reviews;
            let { review } = await (this.isCreate ? post : put)(inputs);
            let { _id: _review } = review || {};

            // DELETE / POST photos
            const _photos__toDelete = _photos.filter((_photo) => !photos.some(({ _id }) => _id == _photo));
            for (const _id of _photos__toDelete) {
                await api.console.eyes.files.delete({ _id });
            }
            for (const [key, photo] of photos.entries()) {
                if (photo instanceof File) {
                    photos[key] = (await api.console.eyes.files.post({ _review, index: `photos/${key}` }, photo))?.file;
                } else photos[key] = photo;
            }
            _photos = photos.map(({ _id }) => _id).filter((value) => value);

            for (const [key, receipt] of receipts.entries()) {
                if (receipt.img instanceof File) {
                    receipt.img = (await api.console.eyes.files.post({ _review, index: `receipts/${key}` }, receipt.img))?.file;
                }
                receipt._img = receipt?.img?._id || null;
                receipts[key] = receipt;

                delete receipts[key].img;
            }
            for (const { _img } of this.receipts) {
                const imgToDelete = !receipts.some((receipt) => receipt._img == _img);
                if (imgToDelete) await api.console.eyes.files.delete({ _id: _img });
            }

            // Document PUT
            review = (await put({ _id: _review, _photos, receipts }))?.review;

            alert("저장되었습니다");
            this.$emit("input", review);
            this.shows = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.review-content {
    ::v-deep * {
        max-width: 100%;
    }
    ::v-deep p:last-of-type {
        margin-bottom: 0;
    }
}
</style>
