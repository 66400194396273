<template>
    <v-dialog v-model="showsDialog" max-width="1280">
        <template #activator="{attrs,on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title class="subtitle-1">
                <span>대량업로드</span>
            </v-card-title>
            <v-divider />
            <v-file-input v-model="excelFile" label="엑셀파일" prepend-icon="mdi-file-excel" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" placeholder="엑셀 다운로드와 동일한 양식으로 파일을 업로드해주세요" v-bind="attrs_input" class="mx-6 my-3" />
            <v-divider />
            <v-card-text class="pa-0">
                <v-data-table :items="excelSheet" :headers="headers"> </v-data-table>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn color="grey" text @click="cancel">취소</v-btn>
                <v-btn color="primary" text @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import api from "@/api";
import XLSX from "xlsx";
import CryptoAES from "@/plugins/crypto-aes";
import { makeNickname } from "@/plugins/makeNickname";
import { attrs_input, USER_TYPES } from "@/assets/variables";

const headers = [
    { text: "아이디", align: "center", width: 200, value: "아이디" },
    { text: "비밀번호", align: "center", width: 200, value: "비밀번호" },
    { text: "닉네임", align: "center", width: 200, value: "닉네임" },
    { text: "이름", align: "center", width: 200, value: "이름" },
    { text: "연락처", align: "center", width: 200, value: "연락처" },
    { text: "이벤트수신", align: "center", width: 200, value: "이벤트수신" },
];

export default {
    data() {
        return {
            excelFile: null,
            excelSheet: [],
            showsDialog: false,

            headers,
            attrs_input,
        };
    },
    computed: {
        users() {
            return this.excelSheet.map((user) => ({
                type: USER_TYPES.PERSON.value,
                username: user["아이디"],
                password: CryptoAES.encrypt(user["비밀번호"]),
                nickname: user["닉네임"] || makeNickname(),
                name: user["이름"],
                phone: user["연락처"],
                isDummy: true,
                allowsAds: user["이벤트수신"] == "동의",
                isBlacklisted: user["주의회원여부"] == "주의",
            }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        excelFile() {
            if (this.excelFile) {
                const reader = new FileReader();
                reader.onload = async () => {
                    const workBook = XLSX.read(reader.result, { type: "binary" });
                    const sheets = workBook.SheetNames.map((key) => XLSX.utils.sheet_to_json(workBook.Sheets[key]));
                    this.excelSheet = sheets[0]; // 첫번째 시트
                };
                reader.readAsBinaryString(this.excelFile);
            }
        },
    },
    methods: {
        async init() {},
        open() {
            this.showsDialog = true;
        },
        close() {
            this.excelFile = null;
            this.excelSheet = [];
            this.showsDialog = false;
        },
        cancel() {
            this.close();
        },
        async save() {
            for (var user of this.users) {
                await api.console.users.post(user);
            }
            this.$emit("save");
            this.close();
        },
    },
};
</script>
