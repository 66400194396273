<template>
    <v-dialog v-model="shows" max-width="1080" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-if="isLoaded" height="100%">
            <v-card-title>
                리뷰 상세
                <v-spacer />
                <v-icon @click="shows = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="height: calc(90vh - 120px); overflow-y: auto">
                <v-row no-gutters style="min-height: calc(100% - 3px)">
                    <v-col>
                        <v-card-subtitle class="grey lighten-4">작성자 정보</v-card-subtitle>
                        <v-divider />
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">이름</v-col>
                            <v-col cols="auto">{{ inputs?.writer?.name }}</v-col>
                        </v-row>
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">닉네임</v-col>
                            <v-col cols="auto">{{ inputs?.writer?.nickname }}</v-col>
                        </v-row>
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">연락처</v-col>
                            <v-col cols="auto">{{ inputs?.writer?.phone?.phoneNumberFormat?.() }}</v-col>
                        </v-row>
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">이메일</v-col>
                            <v-col cols="auto">{{ inputs?.writer?.email }}</v-col>
                        </v-row>
                        <v-divider />
                        <v-card-subtitle class="grey lighten-4">기본정보</v-card-subtitle>
                        <v-divider />
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">병원</v-col>
                            <v-col cols="auto">{{ inputs?.location?.name }} / {{ inputs?.location?.areaName }}</v-col>
                        </v-row>
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">의사</v-col>
                            <v-col cols="auto">{{ inputs?.doctor }}</v-col>
                        </v-row>
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">진료</v-col>
                            <v-col cols="auto">{{ services }}</v-col>
                        </v-row>
                        <v-row no-gutters justify="space-between" class="ma-3">
                            <v-col cols="auto">금액</v-col>
                            <v-col cols="auto">{{ inputs?.charges?.total?.format?.() || "-" }} 원</v-col>
                        </v-row>
                        <v-divider />
                        <review-view-stars :value="inputs" readonly />
                    </v-col>
                    <v-col cols="auto">
                        <v-divider vertical />
                    </v-col>
                    <v-col cols="8">
                        <v-card-subtitle class="grey lighten-4 d-flex align-center justify-space-between"> 리뷰 상세정보 </v-card-subtitle>
                        <v-divider />
                        <v-row no-gutters>
                            <v-col cols="auto" class="ma-3 font-weight-bold">제목 : </v-col>
                            <v-col class="ma-3">{{ inputs?.subject }}</v-col>
                        </v-row>
                        <v-divider />
                        <v-card-text class="review-content">
                            <div v-html="inputs?.content" />
                            <template v-for="photo in inputs?.photos || []">
                                <v-img :key="photo._id" :src="photo.url" class="my-3 rounded-xl" />
                            </template>
                        </v-card-text>
                        <v-divider />
                        <v-row class="ma-0 pa-2">
                            <v-col cols="12" class="pa-2">
                                영수증 -
                                <template v-if="!!inputs?.receipts?.length"> 인증 </template>
                                <template v-else> 미인증 </template>
                            </v-col>
                            <template v-if="inputs?.receipts?.length">
                                <v-col cols="12" class="pa-2">
                                    <image-tiles :images="(inputs?.receipts || []).map((receipt) => receipt?.img?.url).filter((item) => item)" tilesOnly />
                                </v-col>
                            </template>
                        </v-row>
                        <v-divider />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="d-flex align-center subtitle-2">
                <i class="mx-1" v-if="inputs.meta.isRewarded"> * 보상 지급 완료 </i>
                <i class="mx-1" v-else> * 보상 미지급 </i>
                <v-spacer />
                <review-edit-reward v-model="inputs" hasCustomActivator @input="$emit('input', inputs)">
                    <v-btn color="secondary" large v-if="inputs.meta.isRewarded">보상 지급 확인 및 수정</v-btn>
                    <v-btn color="secondary" large v-else>보상 지급</v-btn>
                </review-edit-reward>
            </v-card-actions>
        </v-card>
        <daum-postcode ref="daum-postcode" @change="setAddress" />
    </v-dialog>
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";

import api from "@/api";
import { attrs_input, SERVICES } from "@/assets/variables";
import { getGeolocation } from "@/plugins/getGeolocation";
import ReviewViewStars from "./review-view-stars.vue";
import ImagePopup from "../dumb/image-popup.vue";
import ReviewEditReward from "./review-edit-reward.vue";
import ImageTiles from "@/components/dumb/image-tiles/image-tiles.vue";

const initReview = () => ({
    writer: {
        name: null,
        phone: null,
        email: null,
        nickname: null,
    },
    location: {
        areaName: null,
        name: null,
    },
    georeview: {
        type: "Point",
        coordinates: null,
    },
    photos: [],
    receipts: [],
});

export default {
    components: {
        DaumPostcode,
        ReviewViewStars,
        ImagePopup,
        ReviewEditReward,
        ImageTiles,
    },
    props: {
        value: { type: Object },
    },
    data: () => ({
        shows: false,
        isLoaded: false,

        inputs: {},

        attrs_input,
    }),
    computed: {
        _review() {
            return this.value?._id;
        },
        isCreate() {
            return !this._review;
        },
        services() {
            return (this.value?.services || [])
                ?.map?.((key) => SERVICES[key]?.text)
                .flat()
                .join(", ");
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            this.isLoaded = false;
            if (this.isCreate) {
                this.inputs = { ...initReview() };
            } else {
                const { review } = await api.console.eyes.reviews.get({ _id: this._review });
                this.inputs = review;
            }
            this.isLoaded = true;
        },

        validate() {},

        async save() {
            this.validate();

            const { ...inputs } = this.inputs;
            inputs.geolocation = await getGeolocation(inputs.address1);

            // Document POST / PUT

            let review;
            if (this.isCreate) [review] = [(await api.console.eyes.reviews.post(inputs))?.review];
            else [review] = [(await api.console.eyes.reviews.put(inputs))?.review];

            alert("저장되었습니다");
            this.$emit("input", review);
            this.shows = false;
        },
        goHomepage() {
            window.open(this.inputs.homepage, "_blank");
        },
        searchAddress() {
            this.$refs["daum-postcode"].open();
        },
        setAddress({ postcode, address }) {
            this.inputs.postcode = postcode;
            this.inputs.address1 = address;
        },
    },
};
</script>
<style lang="scss" scoped>
.review-content {
    ::v-deep * {
        max-width: 100%;
    }
    ::v-deep p:last-of-type {
        margin-bottom: 0;
    }
}
</style>
