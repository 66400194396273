<template>
    <list-search v-bind="{ showsSearch }" @search="emit">
        <v-row class="ma-ne">
            <v-col sm="6" lg="" class="pa-2">
                <v-select label="시/도" placeholder="전체" v-model="query['sido']" :items="sido" clearable v-bind="attrs_input" />
            </v-col>
            <v-col sm="6" lg="" class="pa-2">
                <v-select label="구/군" placeholder="전체" v-model="query['gugun']" :items="gugun" clearable no-data-text="'시/도'를 먼저 선택하세요" v-bind="attrs_input" />
            </v-col>
            <v-col sm="6" lg="" class="pa-2">
                <v-select label="베스트리뷰" v-model="query['meta.isBest']" :items="isBestItems" item-text="text" item-value="value" v-bind="attrs_input" />
            </v-col>
            <v-col sm="6" lg="" class="pa-2">
                <v-select v-model="query['searchKey']" :items="searchKeys" item-text="text" item-value="value" label="검색대상" v-bind="attrs_input" />
            </v-col>
            <v-col sm="6" lg="" class="pa-2">
                <v-text-field v-model="query['searchValue']" label="검색어" v-bind="attrs_input" @keydown.enter="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import sidoGugun from "@/assets/data/sido-gugun.json";

import ListSearch from "../dumb/list-search.vue";

const sido = sidoGugun.map(({ name } = {}) => name).flat();

const initQuery = (query = {}) => ({
    ...query,

    ["sido"]: query["sido"] ?? null,
    ["gugun"]: query["gugun"] ?? null,

    ["searchKey"]: query["searchKey"] ?? null,
    ["searchValue"]: query["searchValue"] ?? null,

    ["meta.isBest"]: query["meta.isBest"] ?? null,
});

const attrs_input = {
    dense: true,
    outlined: true,
    "hide-details": true,
    "persistent-placeholder": true,
};

const searchKeys = [
    { text: "전체", value: null },
    { text: "병원명", value: "location.name" },
    { text: "작성자 IP", value: "ip" },
    { text: "작성자 이름", value: "writer.name" },
    { text: "작성자 닉네임", value: "writer.nickname" },
    { text: "작성자 연락처", value: "phone" },
    { text: "작성자 이메일", value: "writer.email" },
];

const isBestItems = [
    { text: "전체", value: null },
    { text: "일반", value: JSON.stringify({ $ne: true }) },
    { text: "베스트", value: "true" },
];

export default {
    components: {
        ListSearch,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        sido,
        searchKeys,
        isBestItems,

        attrs_input,
    }),
    computed: {
        gugun() {
            return (sidoGugun.find(({ name } = {}) => name == this.query.sido)?.gugun || []).map(({ name } = {}) => name).flat();
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { ...query } = this.query;

            if (!query["sido"]) delete query["sido"];
            if (!query["gugun"]) delete query["gugun"];

            if (!query["searchKey"]) delete query["searchKey"];
            if (!query["searchValue"]) delete query["searchValue"];

            if (query["meta.isBest"] == null) delete query["meta.isBest"];

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
