var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "320",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.isLoaded ? _c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card-title', [_vm.isCreate ? [_vm._v("태그 생성")] : [_vm._v("태그 상세")], _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 2), _c('v-divider'), _c('v-card-text', {
    staticClass: "px-4 pt-6 pb-0"
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "유형",
      "items": _vm.types
    },
    on: {
      "input": function ($event) {
        return _vm.init({
          isReset: true
        });
      }
    },
    model: {
      value: _vm.inputs.type,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "type", $$v);
      },
      expression: "inputs.type"
    }
  }, 'v-select', _vm.attrs_input, false)), _c('v-tabs-items', {
    attrs: {
      "value": _vm.inputs.type,
      "vertical": ""
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": _vm.TAG_TYPES.address.value
    }
  }, [_c('tag-select-address', _vm._b({
    model: {
      value: _vm.inputs.text,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "text", $$v);
      },
      expression: "inputs.text"
    }
  }, 'tag-select-address', {
    attrs_input: _vm.attrs_input
  }, false))], 1), _c('v-tab-item', {
    attrs: {
      "value": _vm.TAG_TYPES.location.value
    }
  }, [_c('tag-select-location', _vm._b({
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  }, 'tag-select-location', {
    attrs_input: _vm.attrs_input
  }, false))], 1), _c('v-tab-item', {
    attrs: {
      "value": _vm.TAG_TYPES.service.value
    }
  }, [_c('tag-select-service', _vm._b({
    model: {
      value: _vm.inputs.text,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "text", $$v);
      },
      expression: "inputs.text"
    }
  }, 'tag-select-service', {
    attrs_input: _vm.attrs_input
  }, false))], 1), _c('v-tab-item', {
    attrs: {
      "value": _vm.TAG_TYPES.custom.value
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "태그",
      "prefix": "＃"
    },
    model: {
      value: _vm.inputs.text,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "text", $$v);
      },
      expression: "inputs.text"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "px-3"
  }, [_c('v-switch', _vm._b({
    staticClass: "pa-0 ma-0 ml-1",
    attrs: {
      "inset": "",
      "dense": false
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.inputs.isOn ? "메인 표시" : "메인 미표시") + " ")];
      },
      proxy: true
    }], null, false, 890236251),
    model: {
      value: _vm.inputs.isOn,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "isOn", $$v);
      },
      expression: "inputs.isOn"
    }
  }, 'v-switch', _vm.attrs_input, false)), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 저장하기 ")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }