<template>
    <v-autocomplete label="태그" prefix="＃" suffix="안과" v-model="text" :items="items" v-bind="attrs_input" />
</template>

<script>
import { attrs_input } from "@/assets/variables";
import sidoGugun from "@/assets/data/sido-gugun.json";
const items = [...sidoGugun.flatMap(({ name, gugun }) => [name, ...gugun.map((item) => `${name} ${item.name}`)])];

export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        text: null,

        items,
        attrs_input: { ...attrs_input, hideDetails: false },
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.text = this.value;
        },
        text() {
            this.$emit("input", this.text);
        },
    },
    methods: {
        sync() {
            this.text = this.value;
        },
    },
};
</script>

<style></style>
