<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <list-heading v-model="showsSearch" title="안과 목록" showsFilterButton />
            <location-list-search v-model="filter" :showsSearch="showsSearch" @search="manualSearch" />

            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                        <span> <v-icon>mdi-file-excel</v-icon> 엑셀 다운로드 </span>
                    </v-btn>
                    <location-massive-upload>
                        <template #activator="{ attrs, on }">
                            <v-btn color="white" class="green--text px-2 ml-1" v-bind="attrs" v-on="on">
                                <span> <v-icon>mdi-file-excel</v-icon> 대량 업로드 </span>
                            </v-btn>
                        </template>
                    </location-massive-upload>
                </v-col>
            </v-row>

            <v-data-table :items="items" :headers="headers" hide-default-footer disable-sort class="my-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.homepage`]="{ value }">
                    <template v-if="value">
                        <v-btn icon text :href="value" target="_blank"> <v-icon>mdi-link</v-icon> </v-btn>
                    </template>
                    <template v-else>-</template>
                </template>
                <template #[`item.actions`]="{ item }">
                    <location-view :value="item" @input="update">
                        <template #activator="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" text icon color="primary" large><v-icon>mdi-pencil</v-icon></v-btn>
                        </template>
                    </location-view>
                    <v-btn icon text color="red" @click="remove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="my-4" @input="search()" />
            <location-view @input="update">
                <template #activator="{ attrs, on }">
                    <v-btn v-bind="attrs" v-on="on" fab fixed right bottom color="primary" large><v-icon>mdi-pencil</v-icon></v-btn>
                </template>
            </location-view>
        </v-responsive>
    </v-layout>
</template>

<script>
import ListHeading from "@/components/console/dumb/list-heading.vue";
import LocationView from "@/components/console/eyes/location-view.vue";
import LocationListSearch from "@/components/console/eyes/location-list-search.vue";
import LocationMassiveUpload from "@/components/console/eyes/location-massive-upload.vue";

import api from "@/api";
import XLSX from "xlsx";
const formatter_dateTime = (value) => value?.toDateTime?.() || "-";
const headers = [
    { text: "장소명", value: "name" },
    { text: "연락처", value: "phone" },
    { text: "이메일", value: "email" },
    { text: "사이트", value: "homepage" },
    { text: "주소", value: "address" },
    { text: "후기", value: "meta.reviewsCount.total", formatter: (value) => `${value || 0} 건` },
    { text: "즐겨찾기", value: "_bookmarks.length", formatter: (value) => `${value} 건` },
    { text: "생성일", value: "createdAt", width: 110, formatter: formatter_dateTime },
    { text: "", value: "actions", width: 140, align: "right" },
];

export default {
    components: {
        ListHeading,
        LocationView,
        LocationListSearch,
        LocationMassiveUpload,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            filter: {
                sido: this.$route.query.sido || null,
                gugun: this.$route.query.gugun || null,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
            },

            page: +this.$route.query.page || 1,
            totalCount: 0,
            limit: 10,

            locations: [],
            headers,
        };
    },
    computed: {
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        items() {
            return this.locations;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        init() {
            this.search(false);
        },
        manualSearch() {
            this.page = 1;
            this.search();
        },
        async search(routable = true) {
            const { page, skip, limit } = this;
            if (routable) this.$router.push({ query: Object.assign({}, this.filter, { page }) });
            var {
                summary: { totalCount },
                locations,
            } = await api.console.eyes.locations.gets({
                headers: { skip, limit },
                params: this.filter,
            });

            this.locations = locations;
            this.totalCount = totalCount;
        },
        async remove(item) {
            if (!confirm(`안과를 삭제하시겠습니까?\n\n제목:    "${item?.subject || ""}"\n작성일: ${item?.createdAt?.toDateTime()}`)) return;

            await api.console.eyes.locations.delete(item);
            this.locations = this.locations.filter((content) => content._id !== item._id);
            alert("안과가 삭제되었습니다.");
        },
        async update(item) {
            const index = this.locations.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.locations.splice(index, 1, item);
            else {
                this.locations = [item, ...this.locations];
                this.totalCount += 1;
            }
        },

        async excel() {
            try {
                const { locations } = await api.console.eyes.locations.gets({
                    headers: {
                        limit: 0,
                    },
                    params: this.filter,
                });
                const rows = locations.map((item) => ({
                    _id: item?._id,
                    name: item?.name,
                    phone: item?.phone,
                    email: item?.email,
                    homepage: item?.homepage,
                    postcode: item?.postcode,
                    address1: item?.address1,
                    address2: item?.address2,
                }));
                const workbook = new XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet(rows);
                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, `안과목록-${Date.now().toDateTime()}.xlsx`);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },
    },
};
</script>
