var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._b({
    attrs: {
      "label": "태그",
      "prefix": "＃",
      "items": _vm.items
    },
    model: {
      value: _vm.text,
      callback: function ($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  }, 'v-autocomplete', _vm.attrs_input, false));

}
var staticRenderFns = []

export { render, staticRenderFns }