<template>
    <v-edit-dialog large @close="sync" @save="save" cancel-text="취소" save-text="저장">
        <slot v-if="hasCustomActivator" />
        <template v-if="value.tags.length == 0">-</template>
        <template v-if="value.tags.length == 1">{{ value.tags[0]?.tag }}</template>
        <template v-if="value.tags.length > 1">{{ value.tags[0]?.tag }} 외 {{ value.tags.length - 1 }}</template>
        <template #input>
            <v-row>
                <v-col>
                    <autocomplete-tags v-model="inputs._tags" label="태그" persistent-placeholder outlined hide-details />
                </v-col>
            </v-row>
            <v-divider class="mx-n4" style="position:absolute; width: 100%;" />
        </template>
    </v-edit-dialog>
</template>

<script>
import api from "@/api";
import AutocompleteTags from "./autocomplete-tags.vue";
export default {
    components: {
        AutocompleteTags,
    },
    props: {
        value: { type: Object, default: () => ({}) },
        hasCustomActivator: { type: Boolean, default: false },
    },
    data: () => ({
        inputs: {
            _id: null,
            meta: {
                likes: 0,
            },
        },
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "value._tags"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            const { _id, _tags } = this.value;
            this.inputs = { _id, _tags };
        },
        async save() {
            let { _id, _tags, tags } = this.inputs;
            const { review } = await api.console.eyes.reviews.put({ _id, _tags });
            console.log({ review });
            [{ _tags, tags }] = [review];
            this.$emit("input", { ...this.value, ...review, _tags, tags });
        },
    },
};
</script>

<style></style>
