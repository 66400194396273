<template>
    <v-autocomplete label="태그" prefix="＃" v-model="$data._location" :items="items" item-value="value" v-bind="attrs_input">
        <template #item="{item}"> {{ item.text }} - {{ item.address1 }} {{ item.address2 }} </template>
    </v-autocomplete>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import api from "@/api";

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        _location: null,

        locations: [],
        attrs_input: { ...attrs_input, hideDetails: false },
    }),
    computed: {
        items() {
            return this.locations.map(({ _id: value, name: text, ...data }) => ({ ...data, value, text }));
        },
        text() {
            return this.items.find(({ value }) => value == this.$data._location)?.text || null;
        },
    },
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        "value._location"() {
            this.sync();
        },
        "$data._location"() {
            const { _location } = this.$data;
            const { text } = this;
            const input = { ...this.value, _location, text };
            this.$emit("input", input);
        },
    },
    methods: {
        sync() {
            this.$data._location = this.value._location;
        },
        async init() {
            const { locations } = await api.console.eyes.locations.gets();
            this.locations = locations;
        },
    },
};
</script>

<style></style>
