var render = function render(){
  var _vm$item, _vm$item$display, _vm$item$display$star, _vm$item$display$star2, _vm$item2, _vm$item2$display, _vm$item2$display$end, _vm$item2$display$end2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "640px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_vm.isCreate ? _c('v-btn', _vm._g({
          attrs: {
            "bottom": "",
            "color": "accent",
            "dark": "",
            "fab": "",
            "fixed": "",
            "right": ""
          }
        }, on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1) : _c('v-icon', _vm._g({
          attrs: {
            "small": ""
          }
        }, on), [_vm._v(" mdi-pencil ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.dialog ? _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v(" 띠배너 " + _vm._s(_vm.item._id ? "수정" : "생성") + " ")])]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-6"
  }, [_c('v-row', [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "label": "코드"
    },
    model: {
      value: _vm.item.code,
      callback: function ($$v) {
        _vm.$set(_vm.item, "code", $$v);
      },
      expression: "item.code"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "label": "명칭"
    },
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "label": "URL"
    },
    model: {
      value: _vm.item.href,
      callback: function ($$v) {
        _vm.$set(_vm.item, "href", $$v);
      },
      expression: "item.href"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('image-input', {
    attrs: {
      "label": "PC 이미지",
      "imageSrc": _vm.imagePcUrl
    },
    model: {
      value: _vm.image.pc,
      callback: function ($$v) {
        _vm.$set(_vm.image, "pc", $$v);
      },
      expression: "image.pc"
    }
  }), _c('image-input', {
    attrs: {
      "label": "Moblie 이미지",
      "imageSrc": _vm.imageMbUrl
    },
    model: {
      value: _vm.image.mb,
      callback: function ($$v) {
        _vm.$set(_vm.image, "mb", $$v);
      },
      expression: "image.mb"
    }
  }), _c('v-row', {
    staticClass: "px-1"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "label": "노출",
      "color": "secondary"
    },
    model: {
      value: _vm.item.display.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.item.display, "enabled", $$v);
      },
      expression: "item.display.enabled"
    }
  }, 'v-switch', _vm.switchAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "color": "secondary",
      "label": "기간노출"
    },
    model: {
      value: _vm.item.display.hasPeriod,
      callback: function ($$v) {
        _vm.$set(_vm.item.display, "hasPeriod", $$v);
      },
      expression: "item.display.hasPeriod"
    }
  }, 'v-switch', _vm.switchAttrs, false))], 1)], 1), _c('v-expand-transition', {
    model: {
      value: _vm.item.display.hasPeriod,
      callback: function ($$v) {
        _vm.$set(_vm.item.display, "hasPeriod", $$v);
      },
      expression: "item.display.hasPeriod"
    }
  }, [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.display.hasPeriod,
      expression: "item.display.hasPeriod"
    }]
  }, [_c('v-col', [_c('v-datetime-field', _vm._b({
    attrs: {
      "value": (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : (_vm$item$display = _vm$item.display) === null || _vm$item$display === void 0 ? void 0 : (_vm$item$display$star = _vm$item$display.startedAt) === null || _vm$item$display$star === void 0 ? void 0 : (_vm$item$display$star2 = _vm$item$display$star.toDateTime) === null || _vm$item$display$star2 === void 0 ? void 0 : _vm$item$display$star2.call(_vm$item$display$star),
      "label": "시작일",
      "padding": "0"
    },
    on: {
      "input": function (value) {
        return _vm.item.display.startedAt = value;
      }
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1), _c('v-col', [_c('v-datetime-field', _vm._b({
    attrs: {
      "value": (_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : (_vm$item2$display = _vm$item2.display) === null || _vm$item2$display === void 0 ? void 0 : (_vm$item2$display$end = _vm$item2$display.endedAt) === null || _vm$item2$display$end === void 0 ? void 0 : (_vm$item2$display$end2 = _vm$item2$display$end.toDateTime) === null || _vm$item2$display$end2 === void 0 ? void 0 : _vm$item2$display$end2.call(_vm$item2$display$end),
      "label": "종료일",
      "padding": "0"
    },
    on: {
      "input": function (value) {
        return _vm.item.display.endedAt = value;
      }
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }