var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-switch', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "dense": "",
      "inset": "",
      "hide-details": ""
    },
    on: {
      "click": _vm.save
    },
    model: {
      value: _vm.isOn,
      callback: function ($$v) {
        _vm.isOn = $$v;
      },
      expression: "isOn"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }