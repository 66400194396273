<template>
    <v-switch v-model="isBest" dense inset hide-details class="mr-n6 ml-3 my-0 pa-0" @click="save" />
</template>

<script>
import api from "@/api";
export default {
    props: { value: { type: Object, default: () => ({}) } },
    data: () => ({
        isBest: false,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "value.isBest"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.isBest = this.value.meta.isBest;
        },
        async save() {
            const { value: { _id, meta } = {}, isBest } = this;
            const { review } = await api.console.eyes.reviews.put({ _id, meta: { ...(meta || {}), isBest } });
            this.$emit("input", { ...this.value, meta: review.meta });
            alert("저장되었습니다.");
        },
    },
};
</script>

<style></style>
