export const getGeolocationAndMore = (address = String("")) =>
    new Promise((resolve) => {
        if (kakao && address)
            new kakao.maps.services.Geocoder().addressSearch(address, (result, status) => {
                console.log({ result });
                if (status === kakao.maps.services.Status.OK)
                    resolve({
                        geolocation: {
                            type: "Point",
                            coordinates: [
                                result[0].x, // longitude
                                result[0].y, // latitude
                            ],
                        },
                        postcode: result?.[0]?.road_address?.zone_no,
                        address1: result?.[0]?.road_address?.address_name,
                    });
                else resolve(null);
            });
        else resolve(null);
    });

export default getGeolocationAndMore;
