<template>
    <v-tab-item value="counts">
        <v-data-table v-bind="{ items, headers }" mobile-breakpoint="10000" disable-sort hide-default-footer>
            <template v-for="header in headers" #[`item.${header.value}`]="{ item, value }">
                <v-edit-dialog :key="header.value">
                    <template v-if="header == headers[0]">
                        <component :is="header.is" v-if="header.keys.length == 1" v-model="item[header.keys[0]]" v-bind="header.bind" readonly class="mr-n4" />
                        <component :is="header.is" v-if="header.keys.length == 2" v-model="item[header.keys[0]][header.keys[1]]" v-bind="header.bind" readonly class="mr-n4" />
                        <component :is="header.is" v-if="header.keys.length == 3" v-model="item[header.keys[0]][header.keys[1]][header.keys[2]]" v-bind="header.bind" readonly class="mr-n4" />
                    </template>
                    <template v-else>
                        {{ $data[header.keys[header.keys.length - 1]].format() }}
                        <span v-if="value >= 0" class="font-weight-bold primary--text"> + {{ Math.abs(value).format() }} </span>
                        <span v-else class="font-weight-bold red--text"> - {{ Math.abs(value).format() }} </span>
                        =
                        {{ ($data[header.keys[header.keys.length - 1]] + +value).format() }}
                    </template>
                    <template #input>
                        <component :is="header.is" v-if="header.keys.length == 1" :label="header.text" v-model="item[header.keys[0]]" v-bind="header.bind" class="my-4" />
                        <component :is="header.is" v-if="header.keys.length == 2" :label="header.text" v-model="item[header.keys[0]][header.keys[1]]" v-bind="header.bind" class="my-4" />
                        <component :is="header.is" v-if="header.keys.length == 3" :label="header.text" v-model="item[header.keys[0]][header.keys[1]][header.keys[2]]" v-bind="header.bind" class="my-4" />
                    </template>
                </v-edit-dialog>
            </template>
        </v-data-table>
        <v-divider />
        <v-row no-gutters>
            <v-spacer />
            <v-col cols="auto" class="py-3">
                <v-btn tile color="primary" @click="save">
                    <v-icon class="mr-2">mdi-content-save</v-icon>
                    저장하기
                </v-btn>
            </v-col>
        </v-row>
        <v-divider />
    </v-tab-item>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch } from "@/assets/variables";

const headers = [
    { is: "v-switch", text: "데이터 조정 적용", value: "main.counts.usesAdjustment", bind: { ...attrs_switch, class: "pa-0 ma-0" } },
    { is: "v-text-field", text: "안과후기 조정", value: "main.counts.reviews", bind: { ...attrs_input, type: "number" } },
    { is: "v-text-field", text: "병원정보 조정", value: "main.counts.locations", bind: { ...attrs_input, type: "number" } },
].map((header) => ({ ...header, keys: header.value.split(".") }));

export default {
    components: {},
    data() {
        return {
            reviews: 0,
            locations: 0,

            setting: null,

            Math,
            headers,
        };
    },
    computed: {
        items() {
            if (this.setting) return [this.setting];
            else return [];
        },
        params() {
            const { _id, main } = this.setting || {};
            return { _id, main };
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            [{ totalCount: this.reviews }] = [(await api.console.eyes.reviews.gets({ headers: { skip: 0, limit: 1 } }))?.summary];

            [{ totalCount: this.locations }] = [(await api.console.eyes.locations.gets({ headers: { skip: 0, limit: 1 } }))?.summary];

            [{ setting: this.setting }] = [await api.console.setting.get()];
        },
        async save() {
            await api.console.setting.put(this.params);
            alert("저장되었습니다.");
        },
    },
};
</script>
