<template>
    <v-layout justify-center>
        <v-responsive v-bind="{ ['max-width']: maxWdith }">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">{{ headline }}</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0" v-if="false">
                    <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition v-if="false">
                <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자(시작)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자(종료)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder :disabled="!filter.searchKey" dense hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" rounded text @click="onClickSearch"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table v-bind="{ ...attrs_table, items, headers }">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.no`]="{ item }"> {{ summary.totalCount - (page - 1) * limit - forms.indexOf(item) }} </template>
                <template #[`item.meta.isDone`]="{ item }">
                    <form-edit-meta-is-done :value="item" @input="update" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import FormEditMetaIsDone from "@/components/console/forms/form-edit-meta-is-done.vue";

const toDateTime = (value) => value?.toDateTime?.() || value || "-";
const phoneNumberFormat = (value) => value?.phoneNumberFormat?.() || value || "-";
const formatWriter = ({ nickname, username, phone } = {}) => {
    let writer = "";
    if (nickname) writer += `${nickname}\n`;
    if (username) writer += `${username}\n`;
    return writer.trim();
};

export default {
    components: {
        VDateField,
        FormEditMetaIsDone,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            forms: [],

            searchKeys: [
                { text: "전체", value: null },
                { text: "닉네임", value: "writer.nickname" },
                { text: "연락처", value: "writer.phone" },
            ],
            searchKey: null,
            searchValue: null,
        };
    },
    computed: {
        code() {
            return this.$route.query.code;
        },
        maxWdith() {
            if (this.code == "location-modify-request") return 1080;
            if (this.code == "review-report") return 1280;
            return 1080;
        },
        headline() {
            if (this.code == "location-modify-request") return "정보수정요청 목록";
            if (this.code == "review-report") return "후기신고 목록";
            return "폼메일 목록";
        },
        items() {
            return this.forms;
        },
        headers() {
            return [
                { width: 50, text: "no", value: "no", divider: true },
                { width: 170, text: "요청일", value: "createdAt", formatter: toDateTime, divider: true },
                { width: 160, text: "후기제목", value: "meta.review.subject", cellClass: "text-truncate", shows: ["review-report"].includes(this.code) },
                { width: 160, text: "병원명", value: "meta.location.name", divider: ["review-report"].includes(this.code) },
                { width: 170, text: "신고유형", value: "typeText", divider: ["review-report"].includes(this.code), shows: ["review-report"].includes(this.code) },
                { width: 130, text: "연락처", value: "meta.location.phone", formatter: phoneNumberFormat, divider: true, shows: ["location-modify-request"].includes(this.code) },
                { width: 190, text: ["review-report"].includes(this.code) ? "신고자" : "요청자", value: "writer", formatter: formatWriter, cellClass: "white-space-pre-line text-caption line-height-125" },
                { width: 130, text: "연락처", value: "writer.phone", formatter: phoneNumberFormat, divider: true },
                { width: 90, text: "처리여부", value: "meta.isDone", divider: true, align: "center" },
                { width: 72, value: "actions", align: "right" },
            ].filter(({ shows = true } = {}) => shows);
        },
        attrs_table() {
            return {
                "disable-sort": true,
                "disable-pagination": true,
                "hide-default-footer": true,
                class: "elevation-1 mt-4",
            };
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, forms } = await api.console.forms.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.forms = forms;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        edit(form) {
            this.$router.push(`/console/forms/${form._id}`);
        },
        async remove(form) {
            try {
                if (confirm("폼메일을 삭제하시겠습니까?")) {
                    await api.console.forms.delete(form);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async update(item) {
            const index = this.forms.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.forms.splice(index, 1, item);
            else {
                this.forms = [item, ...this.forms];
                this.totalCount += 1;
            }
        },
        onClickSearch() {
            this.page = 1;
            this.search();
        },
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td {
    &.text-truncate {
        max-width: 1px;
    }
    &.white-space-pre-line {
        white-space: pre-line;
    }
    &.line-height-125 {
        line-height: 1.25;
    }
}
</style>
