export const getGeolocation = (address = String("")) =>
    new Promise((resolve) => {
        if (kakao && address)
            new kakao.maps.services.Geocoder().addressSearch(address, (result, status) => {
                if (status === kakao.maps.services.Status.OK)
                    resolve({
                        type: "Point",
                        coordinates: [
                            result[0].x, // longitude
                            result[0].y, // latitude
                        ],
                    });
                else resolve(null);
            });
        else resolve(null);
    });

export default getGeolocation;
