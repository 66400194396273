<template>
    <v-dialog v-model="shows" max-width="320" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-if="isLoaded" height="100%">
            <v-card-title>
                <template v-if="isCreate">태그 생성</template>
                <template v-else>태그 상세</template>
                <v-spacer />
                <v-icon @click="shows = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-4 pt-6 pb-0">
                <v-select label="유형" v-model="inputs.type" :items="types" v-bind="attrs_input" @input="init({ isReset: true })" />
                <v-tabs-items :value="inputs.type" vertical>
                    <v-tab-item :value="TAG_TYPES.address.value">
                        <tag-select-address v-model="inputs.text" v-bind="{ attrs_input }" />
                    </v-tab-item>
                    <v-tab-item :value="TAG_TYPES.location.value">
                        <tag-select-location v-model="inputs" v-bind="{ attrs_input }" />
                    </v-tab-item>
                    <v-tab-item :value="TAG_TYPES.service.value">
                        <tag-select-service v-model="inputs.text" v-bind="{ attrs_input }" />
                    </v-tab-item>
                    <v-tab-item :value="TAG_TYPES.custom.value">
                        <v-text-field label="태그" prefix="＃" v-model="inputs.text" v-bind="attrs_input" />
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-3">
                <v-switch v-model="inputs.isOn" v-bind="attrs_input" inset :dense="false" class="pa-0 ma-0 ml-1">
                    <template #label>
                        {{ inputs.isOn ? "메인 표시" : "메인 미표시" }}
                    </template>
                </v-switch>
                <v-spacer />
                <v-btn large color="primary" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import TagSelectAddress from "./tag-select-address.vue";
import TagSelectService from "./tag-select-service.vue";
import TagSelectLocation from "./tag-select-location.vue";

import api from "@/api";
import { attrs_input, TAG_TYPES } from "@/assets/variables";
const types = Object.values(TAG_TYPES);
const initLocation = () => ({
    type: TAG_TYPES.address.value,
    text: null,
    isOn: true,
    _location: null,
});

export default {
    components: {
        TagSelectAddress,
        TagSelectService,
        TagSelectLocation,
    },
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        isLoaded: false,

        inputs: initLocation(),

        types,
        TAG_TYPES,
        attrs_input: { ...attrs_input, hideDetails: false },
    }),
    computed: {
        _tag() {
            return this.value?._id;
        },
        isCreate() {
            return !this._tag;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init({ isReset = this.isCreate } = {}) {
            this.isLoaded = false;
            if (isReset) {
                this.inputs = { ...initLocation(), type: this.inputs.type };
            } else {
                const { tag } = await api.console.eyes.tags.get({ _id: this._tag });
                this.inputs = tag;
            }
            this.isLoaded = true;
        },

        validate() {
            switch (this.inputs.type) {
                case TAG_TYPES.address.value:
                case TAG_TYPES.service.value:
                case TAG_TYPES.location.value:
                    if (!this.inputs.text) throw new Error("태그를 선택하세요");
                    break;
                case TAG_TYPES.custom.value:
                    if (!this.inputs.text) throw new Error("태그를 입력하세요");
                    break;

                default:
                    throw new Error("잘못된 요청입니다");
            }
        },

        async save() {
            try {
                this.validate();
            } catch (error) {
                alert(error.message);
                return;
            }

            const { ...inputs } = this.inputs;
            // Document POST / PUT

            let tag;
            if (this.isCreate) [tag] = [(await api.console.eyes.tags.post(inputs))?.tag];
            else [tag] = [(await api.console.eyes.tags.put(inputs))?.tag];

            alert("저장되었습니다");
            this.$emit("input", tag);
            this.shows = false;
        },

        isType(key) {
            return TAG_TYPES[key]?.value == this.inputs?.type;
        },
    },
};
</script>
