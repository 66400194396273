<template>
    <v-edit-dialog large @close="sync" @save="save" cancel-text="취소" save-text="저장">
        <slot v-if="hasCustomActivator" />
        {{ (value.meta.likes || 0)?.format?.() }} 건
        <template #input>
            <v-row>
                <v-col>
                    <v-text-field v-model="inputs.meta.likes" type="number" label="추천수" persistent-placeholder outlined hide-details dense />
                </v-col>
            </v-row>
            <v-divider class="mx-n4" style="position:absolute; width: 100%;" />
        </template>
    </v-edit-dialog>
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: Object, default: () => ({}) },
        hasCustomActivator: { type: Boolean, default: false },
    },
    data: () => ({
        inputs: {
            _id: null,
            meta: {
                likes: 0,
            },
        },
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "value.reward"() {
            this.sync();
        },
        "value.meta.likes"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.inputs = {
                ...this.value,
                meta: {
                    ...this.value?.meta,
                    likes: this.value?.meta?.likes || 0,
                },
            };
        },
        async save() {
            const { _id, meta } = this.inputs;
            meta.likes = +meta.likes;
            const { review } = await api.console.eyes.reviews.put({ _id, meta });
            this.$emit("input", { ...this.value, meta: review.meta });
        },
    },
};
</script>

<style></style>
