<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <list-heading v-model="showsSearch" title="회원 목록" showsFilterButton />
            <user-list-search v-model="filter" v-bind="{ showsSearch }" @search="search" />

            <v-row class="mt-n4">
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2 ml-1" @click="excel"> <v-icon>mdi-file-excel</v-icon> 엑셀 다운로드 </v-btn>
                    <user-uploader @save="$router.go()">
                        <template #activator="{attrs,on}">
                            <v-btn color="white" class="green--text px-2 ml-1" v-bind="attrs" v-on="on"> <v-icon>mdi-file-excel</v-icon> 대량 업로드 </v-btn>
                        </template>
                    </user-uploader>
                </v-col>
            </v-row>

            <v-data-table :headers="headers" :items="users" disable-sort disable-pagination hide-default-footer class="elevation-1" :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template v-slot:[`item.isDummy`]="{ item }">
                    <v-switch v-model="item.isDummy" color="secondary lighten-1" readonly :ripple="false" hide-details inset dense class="mt-0 pa-0" @change="updateUser({ _id: item._id, isDummy: item.isDummy })"></v-switch>
                </template>
                <template v-slot:[`item.allowsAds`]="{ item }">
                    <v-switch v-model="item.allowsAds" color="success lighten-1" readonly :ripple="false" hide-details inset dense class="mt-0 pa-0" @change="updateUser({ _id: item._id, allowsAds: item.allowsAds })"></v-switch>
                </template>
                <template v-slot:[`item.isBlacklisted`]="{ item }">
                    <v-switch v-model="item.isBlacklisted" color="red lighten-1" hide-details inset dense class="mt-0 pa-0" @change="updateUser({ _id: item._id, isBlacklisted: item.isBlacklisted })"></v-switch>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)"> mdi-pencil </v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mx-4" @input="search()"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserUploader from "@/components/console/user/user-uploader.vue";
import UserListSearch from "@/components/console/user/user-list-search.vue";

import api from "@/api";
import XLSX from "xlsx";
import { mdiMicrosoftExcel } from "@mdi/js";

const headers = [
    { width: 170, text: "가입일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 90, text: "유형", value: "typeText" },
    { width: 190, text: "아이디", value: "username" },
    { width: 160, text: "닉네임", value: "nickname" },
    { width: 90, text: "이름", value: "name" },
    { width: 130, text: "연락처", value: "phone", formatter: (value) => value?.phoneNumberFormat?.() || value || "-" },
    { width: 100, text: "관리자생성", value: "isDummy" },
    { width: 100, text: "이벤트수신", value: "allowsAds" },
    { width: 90, text: "주의회원", value: "isBlacklisted" },
    { width: 60, text: "", value: "actions", align: "center" },
];

export default {
    components: {
        ListHeading,
        UserUploader,
        UserListSearch,
    },
    data() {
        return {
            mdiMicrosoftExcel,
            showsSearch: true,

            filter: {
                nickname: this.$route.query.nickname || null,
                phone: this.$route.query.phone || null,
                _level: this.$route.query._level || null,
                isBlacklisted: JSON.parse(this.$route.query.isBlacklisted || "null"),
                isApproved: JSON.parse(this.$route.query.isApproved || "null"),
                isWithdrawn: JSON.parse(this.$route.query.isWithdrawn || "null"),
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            tabIndex: 0,

            headers,
            users: [],
            summary: { totalCount: 0 },
        };
    },
    created() {
        this.search();
    },
    methods: {
        async search() {
            try {
                this.$router.push({
                    query: Object.assignDefined({}, this.$route.query, this.filter, {
                        page: this.page,
                    }),
                });

                var { summary, users } = await api.console.users.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.users = users;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },

        create() {
            this.$router.push(`/console/users/create`);
        },

        edit(user) {
            this.$router.push({
                path: `/console/users/${user._id}`,
            });
        },

        async updateUser(user) {
            try {
                await api.console.users.put(user);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },

        async excel() {
            try {
                var { users } = await api.console.users.gets({
                    headers: {
                        limit: 0,
                    },
                    params: this.filter,
                });

                var rows = users.map((user) => ({
                    가입일자: user?.createdAt?.toDateTime?.(),
                    아이디: user?.username,
                    비밀번호: "",
                    닉네임: user?.nickname,
                    이름: user?.name,
                    연락처: user?.phone,
                    이벤트수신: user?.allowsAds ? "동의" : "미동의",
                    주의회원여부: user?.isBlacklisted ? "주의" : "-",
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "회원목록.xlsx");
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },

        async sendemail() {
            try {
                var users;
                switch (this.messageTarget) {
                    case "search": {
                        users = (
                            await api.console.users.gets({
                                params: this.filter,
                            })
                        ).users;
                        break;
                    }
                    case "selected": {
                        if (!this.selected.length) throw new Error("사용자를 선택해주세요");
                        users = this.selected;
                        break;
                    }
                }

                var { success } = await api.v1.message.post({
                    emails: users.map((user) => user.email),
                    subject: this.messageSubject,
                    message: this.message,
                });

                if (success) alert("발송되었습니다");
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>
