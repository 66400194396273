var render = function render(){
  var _vm$value;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', {
    attrs: {
      "large": "",
      "cancel-text": "취소",
      "save-text": "저장"
    },
    on: {
      "open": function ($event) {
        return _vm.$nextTick(_vm.sync);
      },
      "save": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-row', [_c('v-col', [_c('review-write-clinic', {
          attrs: {
            "label": "병원명",
            "outlined": "",
            "dense": "",
            "persistent-placeholder": ""
          },
          model: {
            value: _vm.inputs,
            callback: function ($$v) {
              _vm.inputs = $$v;
            },
            expression: "inputs"
          }
        })], 1)], 1), _c('v-divider', {
          staticClass: "mx-n4",
          staticStyle: {
            "position": "absolute",
            "width": "100%"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.hasCustomActivator ? _vm._t("default") : _vm._e(), _vm._v(" " + _vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.locationName) + " ")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }