<template>
    <v-dialog v-model="shows" max-width="640" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-if="isLoaded" height="100%" outlined>
            <v-card-title>
                <template v-if="isCreate">병원 생성</template>
                <template v-else>병원 상세</template>
                <v-spacer />
                <v-icon @click="shows = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
                <v-card-subtitle class="grey lighten-4">기본 정보</v-card-subtitle>
                <v-divider />
                <v-row no-gutters class="ma-3">
                    <v-col class="mr-3"> <v-text-field label="병원명" v-model="inputs.name" v-bind="{ ...attrs_input, readonly }" /> </v-col>
                    <v-col></v-col>
                </v-row>
                <v-row no-gutters class="ma-3">
                    <v-col class="mr-3"> <v-text-field label="연락처" v-model="inputs.phone" v-bind="{ ...attrs_input, readonly }" /> </v-col>
                    <v-col> <v-text-field label="이메일" v-model="inputs.email" v-bind="{ ...attrs_input, readonly }" /> </v-col>
                </v-row>
                <v-row no-gutters class="ma-3">
                    <v-col>
                        <v-text-field label="사이트" v-model="inputs.homepage" v-bind="{ ...attrs_input, readonly }" append-icon="mdi-link" @click:append="goHomepage" />
                    </v-col>
                </v-row>
                <v-divider />
                <v-card-subtitle class="grey lighten-4">주소</v-card-subtitle>
                <v-divider />
                <v-row no-gutters class="ma-3" align="center">
                    <v-col class="mr-3"> <v-text-field label="우편번호" v-model="inputs.postcode" v-bind="attrs_input" readonly @click="searchAddress" /> </v-col>
                    <v-col> <v-btn color="secondary" @click="searchAddress">검색</v-btn> </v-col>
                </v-row>
                <v-row no-gutters class="ma-3" align="center">
                    <v-col class="mr-3"> <v-text-field label="기본주소" v-model="inputs.address1" v-bind="attrs_input" readonly @click="searchAddress" /> </v-col>
                    <v-col> <v-text-field label="상세주소" v-model="inputs.address2" v-bind="{ ...attrs_input, readonly }" /> </v-col>
                </v-row>

                <v-divider />
                <v-card-subtitle class="grey lighten-4">태그</v-card-subtitle>
                <v-divider />
                <v-row no-gutters class="ma-3">
                    <v-col> <autocomplete-tags v-model="inputs._tags" v-bind="{ ...attrs_input, readonly }" /> </v-col>
                </v-row>
            </v-card-text>
            <template v-if="!readonly">
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn large color="primary" @click="save"> 저장하기 </v-btn>
                </v-card-actions>
            </template>
        </v-card>
        <daum-postcode ref="daum-postcode" @change="setAddress" />
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";
import { getGeolocation } from "@/plugins/getGeolocation";

import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import AutocompleteTags from "./autocomplete-tags.vue";

const initLocation = () => ({
    _tags: [],
    name: null,
    phone: null,
    email: null,
    homepage: null,
    postcode: null,
    address1: null,
    address2: null,
    geolocation: {
        type: "Point",
        coordinates: null,
    },
});

export default {
    components: {
        DaumPostcode,
        AutocompleteTags,
    },
    props: {
        value: { type: Object },
        readonly: { type: Boolean, default: false },
    },
    data: () => ({
        shows: false,
        isLoaded: false,

        inputs: {},

        attrs_input,
    }),
    computed: {
        _location() {
            return this.value?._id;
        },
        isCreate() {
            return !this._location;
        },
    },
    methods: {
        async init() {
            this.isLoaded = false;
            if (this.isCreate) {
                this.inputs = { ...initLocation() };
            } else {
                const { location } = await api.console.eyes.locations.get({ _id: this._location });
                this.inputs = location;
            }
            this.isLoaded = true;
        },

        validate() {},

        async save() {
            this.validate();

            const { ...inputs } = this.inputs;
            inputs.geolocation = await getGeolocation(inputs.address1);

            // Document POST / PUT

            let location;
            if (this.isCreate) [location] = [(await api.console.eyes.locations.post(inputs))?.location];
            else [location] = [(await api.console.eyes.locations.put(inputs))?.location];

            alert("저장되었습니다");
            this.$emit("input", location);
            this.shows = false;
        },
        goHomepage() {
            window.open(this.inputs.homepage, "_blank");
        },
        searchAddress() {
            if (this.readonly) return;
            this.$refs["daum-postcode"].open();
        },
        setAddress({ postcode, address }) {
            this.inputs.postcode = postcode;
            this.inputs.address1 = address;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
};
</script>
