<template>
    <v-switch v-model="isDone" dense inset hide-details class="mr-n6 ml-3 my-0 pa-0" @click="save" />
</template>

<script>
import api from "@/api";
export default {
    props: { value: { type: Object, default: () => ({}) } },
    data: () => ({
        isDone: false,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "value.isDone"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.isDone = this.value.meta.isDone;
        },
        async save() {
            const { value: { _id, meta } = {}, isDone } = this;
            const { form } = await api.console.forms.put({ _id, meta: { ...(meta || {}), isDone } });
            this.$emit("input", { ...this.value, meta: form.meta });
            alert("저장되었습니다.");
        },
    },
};
</script>

<style></style>
