var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('images-input', {
    attrs: {
      "value": _vm.receipts.map(function (_ref) {
        var img = _ref.img;
        return img;
      }),
      "mode": "hover"
    },
    on: {
      "input": _vm.setImages
    },
    scopedSlots: _vm._u([{
      key: "hover-icons",
      fn: function (_ref2) {
        var index = _ref2.index,
          hover = _ref2.hover;
        return [_c('v-card', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: hover,
            expression: "hover"
          }],
          staticClass: "pa-2",
          attrs: {
            "rounded": "xl"
          }
        }, [_c('v-switch', _vm._b({
          staticClass: "mr-n4 my-n1",
          attrs: {
            "dense": false
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.receipts[index].shows,
            callback: function ($$v) {
              _vm.$set(_vm.receipts[index], "shows", $$v);
            },
            expression: "receipts[index].shows"
          }
        }, 'v-switch', _vm.attrs_switch, false))], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }