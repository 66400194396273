<template>
    <v-combobox v-model="location" :search-input.sync="clinic" :items="items" :loading="loading" :item-text="itemText" no-filter return-object hide-details v-bind="$attrs" />
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // review
    },
    data() {
        return {
            clinic: null,
            location: null,
            locations: [],

            loading: false,
        };
    },
    computed: {
        outOfSync() {
            const locationNotSame = this.value?._location != this.location?._id;
            const clinicNotSame = this.value?.clinic != this.clinic;
            return locationNotSame || clinicNotSame;
        },
        _location() {
            return this.value?._location || this.$route.query._location;
        },
        items() {
            return this.locations;
        },
        filter() {
            const searchValue = this.clinic?.replace(/[.*+?^${}()|[\]\\]/g, "");
            return { searchKey: "name", searchValue, sort: "name" };
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this._location) {
                this.sync()
                    .then(this.search)
                    .then(this.setLocation);
            } else this.sync();
        });
    },
    watch: {
        "value.clinic"() {
            if (this.outOfSync) this.sync();
        },
        "location._id"(_location) {
            if (this.outOfSync) this.emit();
        },
        clinic() {
            this.emit();
            this.search();
        },
    },
    methods: {
        async sync() {
            this.location = this.value?.location || null;
            this.clinic = this.value?.location?.name || this.value?.clinic || null;
        },
        emit() {
            let { location, clinic } = this;
            const _location = location?._id || undefined;
            clinic = location?.name || clinic || null;
            this.$emit("input", { ...this.value, _location, location, clinic });
        },
        async search() {
            try {
                this.loading = true;
                const { filter: params } = this;
                const { locations } = await api.v1.eyes.locations.gets({ params });
                this.locations = locations;

                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        itemText(item) {
            return `${item.name} (${item.address})`;
        },
        setLocation() {
            this.location = this.locations.find(({ _id }) => _id == this._location);
        },
    },
};
</script>

<style></style>
