<template>
    <v-dialog v-model="dialog" max-width="640px">
        <template v-slot:activator="{ on }">
            <v-btn v-if="isCreate" bottom color="accent" dark fab fixed right v-on="on">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-icon v-else small v-on="on">
                mdi-pencil
            </v-icon>
        </template>
        <v-card v-if="dialog">
            <v-card-title>
                <span class="subtitle-1 font-weight-bold"> 띠배너 {{ item._id ? "수정" : "생성" }} </span>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-6">
                <v-row>
                    <v-col>
                        <v-text-field v-model="item.code" label="코드" v-bind="inputAttrs" />
                    </v-col>
                    <v-col>
                        <v-text-field v-model="item.name" label="명칭" v-bind="inputAttrs" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="item.href" label="URL" v-bind="inputAttrs"></v-text-field>
                    </v-col>
                </v-row>
                <image-input v-model="image.pc" label="PC 이미지" :imageSrc="imagePcUrl" />
                <image-input v-model="image.mb" label="Moblie 이미지" :imageSrc="imageMbUrl" />
                <v-row class="px-1">
                    <v-col cols="auto">
                        <v-switch v-model="item.display.enabled" label="노출" color="secondary" v-bind="switchAttrs" />
                    </v-col>
                    <v-col cols="auto">
                        <v-switch v-model="item.display.hasPeriod" color="secondary" label="기간노출" v-bind="switchAttrs" />
                    </v-col>
                </v-row>
                <v-expand-transition v-model="item.display.hasPeriod">
                    <v-row v-show="item.display.hasPeriod">
                        <v-col>
                            <v-datetime-field :value="item?.display?.startedAt?.toDateTime?.()" label="시작일" v-bind="inputAttrs" padding="0" @input="(value) => (item.display.startedAt = value)" />
                        </v-col>
                        <v-col>
                            <v-datetime-field :value="item?.display?.endedAt?.toDateTime?.()" label="종료일" v-bind="inputAttrs" padding="0" @input="(value) => (item.display.endedAt = value)" />
                        </v-col>
                    </v-row>
                </v-expand-transition>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">취소</v-btn>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import ImageInput from "./image-input.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";

const initialData = {
    code: null,
    name: null,
    url: null,

    display: {
        enabled: false,
        period: false,
        startedAt: null,
        endedAt: null,
    },
};
const inputAttrs = {
    dense: true,
    outlined: true,
    placeholder: " ",
    hideDetails: true,
};
const switchAttrs = {
    inset: true,
    class: "mt-0 py-2",
    hideDetails: true,
};

export default {
    components: {
        ImageInput,
        VDatetimeField,
    },
    props: {
        isCreate: { type: Boolean, default: false },
        value: { type: Object, default: null },
    },
    computed: {
        imagePcUrl() {
            return this.value?.imagePc?.url;
        },
        imageMbUrl() {
            return this.value?.imageMb?.url;
        },
    },
    data() {
        return {
            dialog: false,
            item: { ...initialData },
            image: {
                pc: null,
                mb: null,
            },
            inputAttrs,
            switchAttrs,
        };
    },
    methods: {
        async init() {
            // SET initial data
            let target = this.isCreate ? initialData : this.value || {};
            this.item = JSON.parse(JSON.stringify({ ...target }));

            // SET initial image
            if (!this.isCreate) {
                const { imagePc, imageMb } = this.value || {};
                if (imagePc) this.image.pc = await api.getResource(imagePc.url, true);
                if (imageMb) this.image.mb = await api.getResource(imageMb.url, true);
            }
        },

        async save() {
            const { item = {}, isCreate } = this;
            const { _imagePc, _imageMb } = item;

            try {
                const { bandBanner } = isCreate ? await api.console.bandBanners.post(item) : await api.console.bandBanners.put(item);

                for (const _id of [_imagePc, _imageMb]) {
                    if (_id) await api.console.bandBannerFiles.delete({ _id });
                }

                const _bandBanner = bandBanner._id;
                const images = { pc: undefined, mb: undefined };

                if (this.image.pc) [{ file: images.pc }] = [await api.console.bandBannerFiles.post({ _bandBanner, index: "pc", fieldName: "_imagePc" }, this.image.pc)];
                if (images.pc) {
                    bandBanner._imagePc = images.pc?._id;
                    bandBanner.imagePc = images.pc;
                }

                if (this.image.mb) [{ file: images.mb }] = [await api.console.bandBannerFiles.post({ _bandBanner, index: "mb", fieldName: "_imageMb" }, this.image.mb)];
                if (images.mb) {
                    bandBanner._imageMb = images.mb?._id;
                    bandBanner.imageMb = images.mb;
                }

                this.$emit("input", bandBanner);
                if (isCreate) alert("생성되었습니다.");
                else alert("수정되었습니다.");
                this.dialog = false;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
};
</script>

<style></style>
