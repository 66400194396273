<template>
    <v-row class="ma-0 subtitle-2 grey--text text--darken-2 font-weight-regular">
        <v-col cols="2" class="caption font-weight-bold">{{ label }}</v-col>
        <v-col cols="auto" class="pa-0">
            <v-divider vertical />
        </v-col>
        <v-col v-if="!!from"> {{ from }} </v-col>
        <v-col v-else class="grey--text text--lighten-1"> 데이터 없음 </v-col>
        <v-col cols="auto" class="px-0">
            <v-icon :color="!to ? 'transparent' : 'warning'">
                mdi-chevron-right
            </v-icon>
        </v-col>
        <v-col v-if="!!to"> {{ to }} </v-col>
        <v-col v-else class="grey--text text--lighten-1"> 데이터 없음 </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        label: { type: String, default: "필드명" },
        from: { type: String, default: null },
        to: { type: String, default: null },
    },
};
</script>

<style></style>
