<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1440px" width="100%">
                <v-row class="mt-8 mb-2" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">띠배너 관리</v-col>
                    <v-spacer></v-spacer>
                </v-row>

                <v-data-table :headers="headers" :items="items" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:[`item.imagePc`]="{ item }">
                        <v-col class="px-0">
                            <image-popup :src="item?.imagePc?.url" width="80" height="60" />
                        </v-col>
                    </template>
                    <template v-slot:[`item.imageMb`]="{ item }">
                        <v-col class="px-0">
                            <image-popup :src="item?.imageMb?.url" width="80" height="60" />
                        </v-col>
                    </template>
                    <template v-slot:[`item.display.enabled`]="{ item }">
                        <v-switch v-model="item.display.enabled" @change="(enabled) => save({ _id: item._id, display: { ...item.display, enabled } })" v-bind="switchAttrs" />
                    </template>
                    <template v-slot:[`item.display.hasPeriod`]="{ item }">
                        <v-switch v-model="item.display.hasPeriod" @change="(hasPeriod) => save({ _id: item._id, display: { ...item.display, hasPeriod } })" v-bind="switchAttrs" />
                    </template>
                    <template v-slot:[`item.display.startedAt`]="{ item }">
                        <span style="white-space:pre-line">
                            {{ item.display.startedAt ? item.display.startedAt.split(" ").join("\n") : "-" }}
                        </span>
                    </template>
                    <template v-slot:[`item.display.endedAt`]="{ item }">
                        <span style="white-space:pre-line">
                            {{ item.display.endedAt ? item.display.endedAt.split(" ").join("\n") : "-" }}
                        </span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <band-banner-view :value="item" @input="sync" />
                        <v-icon @click="remove(item)" small class="ml-2">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

                <band-banner-view isCreate @input="(item) => sync(item, true)" />
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import BandBannerView from "@/components/console/band-banners/band-banner-view.vue";
import ImagePopup from "@/components/console/band-banners/image-popup.vue";
const headers = [
    { text: "코드", value: "code" },
    { text: "명칭", value: "name" },
    { text: "링크", value: "href" },
    { text: "PC", value: "imagePc", width: 80 },
    { text: "Mobile", value: "imageMb", width: 80 },
    { text: "노출", value: "display.enabled", width: 80 },
    { text: "기간노출", value: "display.hasPeriod", width: 80 },
    { text: "시작일", value: "display.startedAt", width: 110 },
    { text: "종료일", value: "display.endedAt", width: 110 },
    { text: "", value: "actions", width: 80 },
];
const switchAttrs = {
    hideDetails: true,
    inset: true,
    color: "secondary",
    class: "pa-0 ma-0",
    style: "margin-right:-16px!important;",
};

export default {
    components: {
        BandBannerView,
        ImagePopup,
    },
    created() {
        this.init();
    },
    computed: {
        items() {
            return this.bandBanners.map(({ display = {}, createdAt, ...item }) => ({
                ...item,
                display: {
                    ...display,
                    startedAt: display?.startedAt?.toDateTime() || null,
                    endedAt: display?.endedAt?.toDateTime() || null,
                },
                createdAt: createdAt?.toDateTime() || null,
            }));
        },
    },
    data: () => {
        return {
            showsSearch: true,

            page: 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            bandBanners: [],
            headers,

            switchAttrs,
        };
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            try {
                var { summary, bandBanners } = await api.console.bandBanners.gets({
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                });
                this.bandBanners = bandBanners;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async remove(item) {
            try {
                await api.console.bandBanners.delete(item);
                this.bandBanners = this.bandBanners.filter(({ _id }) => _id !== item._id);
                alert("삭제되었습니다.");
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async save(item) {
            try {
                const { bandBanner } = await api.console.bandBanners.put(item);
                this.sync(bandBanner);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        sync(item, isCreate = false) {
            if (isCreate) {
                this.bandBanners.push(item);
                alert("생성되었습니다.");
            } else {
                const index = this.bandBanners.findIndex(({ _id }) => _id == item._id);
                this.bandBanners.splice(index, 1, item);
                alert("수정되었습니다.");
            }
        },
    },
};
</script>
